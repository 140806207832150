import Vue from "vue";
import i18n from "@/plugins/i18n";
import Vuetify from "vuetify";
import "./vuetify.styl"

 Vue.use(Vuetify, {
  theme: {
    primary: "#e2001a",
    secondary: "#707173",
    accent: "#707173",
    error: "#f44336",
    warning: "#ff8700",
    info: "#2196f3",
    success: "#4caf50"
  },
  iconfont: "fa",
  lang: {
    t: (key: any, ...params: any) => i18n.t(key, params)
  }
});

export interface IDataTableHeader {
  text: string | null;
  value: string | null;
  align: Align;
  sortable: boolean;
  class: string[] | string;
  width: string | null;
}

export class DataTableHeader implements IDataTableHeader {
  text: string | null = null;
  value: string | null = null;
  align: Align = Align.Left;
  sortable: boolean = false;
  class: string[] | string = [];
  width: string | null = null;
  constructor(text: string, value: string | null = null, align: Align = Align.Left, className: string[] | string = [], width: string | null = null) {
    this.text = text;
    this.value = value;
    if (value) {
      this.sortable = true;
    }
    this.align = align;
    this.class = className;
    this.width = width;
  }
}

export enum Align {
  Left = "left",
  Center = "center",
  Right = "right"
}

export interface IPagination {
  descending: boolean;
  page: number;
  rowsPerPage: number; // -1 for All
  sortBy: string;
  totalItems: number;
}

export class Pagination implements IPagination {
  descending: boolean = false;
  page: number = 1;
  rowsPerPage: number = 10;
  sortBy!: string;
  totalItems!: number;
  constructor(rowsPerPage: number = 10) {
    this.rowsPerPage = rowsPerPage;
  }
}
