




















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Stepper extends Vue {
  @Prop()
  readonly total!: number;
  @Prop({ default: [] })
  readonly completed!: boolean[];
  @Prop({ default: 10 })
  readonly shown!: number;
  @Prop({ default: 1 })
  readonly min!: number;

  get max() {
    return this.min - 1 + this.total;
  }  
  get actual() {
    return this.min - 1 + this.completed.length;
  }
  isComplete(n:number){
    var actual = n - this.min;
    return this.completed.length > actual && this.completed[actual] == true
  }
  get items() {
    if (this.total <= this.shown) {
      return this.range(this.min, this.max);
    }

    const even = this.shown % 2 === 0 ? 1 : 0;
    const left = Math.floor(this.shown / 2);
    const right = this.max - left + 1 + even;

    if (this.actual > left && this.actual < right) {
      const start = this.actual - left + 2;
      const end = this.actual + left - 2 - even;

      return [this.min, "...", ...this.range(start, end), "...", this.max];
    } else if (this.actual === left) {
      const end = this.actual + left - 1 - even;
      return [...this.range(1, end), "...", this.max];
    } else if (this.actual === right) {
      const start = this.actual - left + 1;
      return [this.min, "...", ...this.range(start, this.max)];
    } else {
      return [...this.range(1, left), "...", ...this.range(right, this.max)];
    }
  }
  range(from: number, to: number) {
    const range = [];
    from = from > 0 ? from : 1;

    for (let i = from; i <= to; i++) {
      range.push(i);
    }

    return range;
  }
}
