










import { Component, Vue } from "vue-property-decorator";
import appStore from "@/stores/app";
import config from "@/config";
import dataProtection from "@/locales/module-visitor/data-protection"

@Component
export default class Complete extends Vue {
  text = "";
  mounted() {
    this.text = dataProtection[appStore.state.locale] 
    || dataProtection[config.locale] 
    || ""
  }
}
