import { render, staticRenderFns } from "./InvitationForm.vue?vue&type=template&id=6bce02f8&"
import script from "./InvitationForm.vue?vue&type=script&lang=ts&"
export * from "./InvitationForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCheckbox,
  VFlex,
  VForm,
  VLayout,
  VTextField,
  VTextarea,
})
