
import jwt from "jsonwebtoken";

export function getExpiration(authorizationHeader: string|null): Date|null {
    if (!authorizationHeader) {
        return null
    }
    const parts = authorizationHeader.split(" ");
    try {
        const token = parts[1];
        const parsedToken = jwt.decode(token);
        const expiration = (parsedToken as any).exp
        return new Date(expiration * 1000)
    } catch (e) {
        console.warn("Can not get toke nexpiration from header " + authorizationHeader)
        return null;
    }
}