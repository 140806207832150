


























import { Component, Vue } from "vue-property-decorator";
import notifications from "@/stores/notifier";

@Component
export default class Notifier extends Vue {
  get visible() {
    return this.messages.length > 0;
  }
  get messages() {
    return notifications.active;
  }
  getClass(messageType: string, prefix: string) {
    let type = messageType.toLowerCase();
    if (type == "success" || type == "info" || type == "warning") {
      return prefix + type;
    }
    return prefix + "danger";
  }
  dismiss(id: number) {
    notifications.remove(id);
  }
}
