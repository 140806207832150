import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=081baca2&scoped=true&"
import script from "./VideoPlayer.vue?vue&type=script&lang=ts&"
export * from "./VideoPlayer.vue?vue&type=script&lang=ts&"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&id=081baca2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "081baca2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VFlex,
  VLayout,
})
