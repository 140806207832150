























































































import { Component, Vue, Emit } from "vue-property-decorator";
import * as moment from "moment";

import AddVisitorDialog from "./invitation/AddVisitorDialog.vue";
import AddAdHocVisitorDialog from "./invitation/AddAdHocVisitorDialog.vue";
import SearchHost from "./invitation/SearchHost.vue";
import VisitorGrid from "./invitation/VisitorGrid.vue";

import api, { IF6040Host } from "@/api";

import invitationStore, { Visitor } from "@/module-admin/stores/invitation";

import formRules from "@/helpers/formRules";

@Component({
  components: {
    SearchHost,
    AddVisitorDialog,
    AddAdHocVisitorDialog,
    VisitorGrid
  }
})
export default class CreateInvitationView extends Vue {
  visitorDialog: boolean = false;
  adHocVisitorDialog: boolean = false;
  rulesSubject: Function[] = [formRules.required];
  get host(): IF6040Host | null {
    return invitationStore.state.host;
  }
  set host(host: IF6040Host | null) {
    invitationStore.update(s => (s.host = host));
  }
  get since() {
    return invitationStore.state.durationFrom;
  }
  set since(value: Date) {
    invitationStore.update(s => {
      s.durationFrom = value;
      if (value > this.until) {
        s.durationTo = moment
          .utc(value)
          .add(1, "hour")
          .toDate();
      }
    });
  }
  get until() {
    return invitationStore.state.durationTo;
  }
  set until(value: Date) {
    invitationStore.update(s => {
      s.durationTo = value;
      if (value < this.since) {
        s.durationFrom = moment
          .utc(value)
          .subtract(1, "hour")
          .toDate();
      }
    });
  }
  get subject() {
    return invitationStore.state.subject;
  }
  set subject(value: string) {
    invitationStore.update(s => (s.subject = value));
  }
  get mailNotification() {
    return invitationStore.state.mailNotification;
  }
  set mailNotification(value: boolean) {
    invitationStore.update(s => (s.mailNotification = value));
  }
  get notes() {
    return invitationStore.state.notes;
  }
  set notes(value: string) {
    invitationStore.update(s => (s.notes = value));
  }
  get isValid() {
    return invitationStore.isValid;
  }
  get isMeetingRoom() {
    return invitationStore.state.isMeetingRoom;
  }
  set isMeetingRoom(value: boolean) {
    invitationStore.update(s => (s.isMeetingRoom = value));
  }
  get meetingRoomNote() {
    return invitationStore.state.meetingRoomNote;
  }
  set meetingRoomNote(value: string) {
    invitationStore.update(s => (s.meetingRoomNote = value));
  }
  get isGroupVisit() {
    return invitationStore.state.nrOfVisitorsInGroupVisit > 0;
  }
  set isGroupVisit(value: boolean) {
    invitationStore.update(s => (s.nrOfVisitorsInGroupVisit = value ? 1 : 0));
  }
  get numberOfVisitorsInGroupVisit() {
    return invitationStore.state.nrOfVisitorsInGroupVisit;
  }
  set numberOfVisitorsInGroupVisit(value: number) {
    invitationStore.update(s => (s.nrOfVisitorsInGroupVisit = value));
  }
  get isCanteen() {
    return invitationStore.state.nrOfMealsInCanteen > 0;
  }
  set isCanteen(value: boolean) {
    invitationStore.update(s => (s.nrOfMealsInCanteen = value ? 1 : 0));
  }
  get numberOfMealsInCanteen() {
    return invitationStore.state.nrOfMealsInCanteen;
  }
  set numberOfMealsInCanteen(value: number) {
    invitationStore.update(s => (s.nrOfMealsInCanteen = value));
  }
  get pictureAllowance(){
    return invitationStore.state.isPictureAllowance
  }
  set pictureAllowance(value: boolean){
    invitationStore.update(s=>(s.isPictureAllowance = value))
  }
  mounted() {
    (this.$refs.form as any).validate();
  }
}
