export default class Filter<T> {
    private filters: Array<(data: T) => string|null|undefined>;

    constructor(...filters: Array<(data: T) => string|null|undefined>) {
        this.filters = filters
    }

    public add(filter: ((data: T) => string|null|undefined)) {
        this.filters.push(filter);
    }

    public apply(array: T[], value: string) {
        if (this.filters.length > 0) {
            var search = (value || "").trim().toLowerCase();
            if (search) {
                return array.filter((item) => this.matches(item, search));

            }
        }
        return array;
    }

    private matches(item: T, search: string): boolean {

        for (var key in this.filters) {
            var value = (this.filters[key](item) || "").toLowerCase()
            if (value.indexOf(search) >= 0) {
                return true;
            }
        }
        return false;
    }
}