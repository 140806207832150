
import i18n from "@/plugins/i18n"

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type Rule = boolean | string

export class FormRules {
  required(value: string): Rule{
    return !!value || (i18n.t("formRules.required") as string);
  }
  email(value: string): Rule {
    return emailPattern.test(value) || (i18n.t("formRules.invalidEmail") as string);
  }
}

export default new FormRules();
