import { render, staticRenderFns } from "./DateTime.vue?vue&type=template&id=153c834c&"
import script from "./DateTime.vue?vue&type=script&lang=ts&"
export * from "./DateTime.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {
  VDatePicker,
  VFlex,
  VLayout,
  VMenu,
  VSelect,
  VTextField,
})
