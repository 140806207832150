import { render, staticRenderFns } from "./VisitorForm.vue?vue&type=template&id=2d8c68e6&"
import script from "./VisitorForm.vue?vue&type=script&lang=ts&"
export * from "./VisitorForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCardActions,
  VSpacer,
  VTextField,
})
