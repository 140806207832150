
import { IF6040Host } from "@/api";
import * as moment from "moment-timezone";

export interface Visitor {
    id: string;
    email: string;
    firstName: string
    lastName: string
    visitTypeId: string;
    conditions: string[];
    pin: string | null;
    companyName: string;
    isAdHoc: boolean
    status: VisitorStatus
}
export enum VisitorStatus{
    New = "New",//Our status on FE only
    Invited = "Invited",
    Preregistered = "PreRegistered",
    Registered = "Registered",
    Deregistered = "Deregistered"
}
export interface VisitType{
    id: string,
    name: string,
    description: string,
    conditions: VisitTypeCondition[]
}
export interface VisitTypeCondition{
    id: string,
    name: string
}
export class InvitationState {
    // Form fields
    id: string | null = null;
    host: IF6040Host | null = null;
    subject: string = "";
    durationFrom: Date;
    durationTo: Date;
    mailNotification: boolean = true;
    visitors: Visitor[] = [];
    notes: string = "";
    isMeetingRoom: boolean = false;
    meetingRoomNote: string = "";
    nrOfVisitorsInGroupVisit: number = 0;
    nrOfMealsInCanteen: number = 0;
    isPictureAllowance: boolean = false;
    // Supporting fields
    visitTypes: VisitType[] = [];
    snapshot: string = "";

    constructor() {
        var now = moment.utc()
        var minutes = now.minutes()
        var hours = now.hours()
        // minutes = minutes > 30 ? 0 : 30;
        if(minutes>0){
            minutes = 0
            hours++
        }
        this.durationFrom = moment
            .utc()
            .hours(hours)
            .minutes(minutes)
            .seconds(0)
            .milliseconds(0)
            .toDate();
        this.durationTo = moment
            .utc()
            .hours(hours)
            .minutes(minutes)
            .add(60, "minutes")
            .seconds(0)
            .milliseconds(0)
            .toDate();
    }
}