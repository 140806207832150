import Vue from "vue";
import VuetifyConfirm from 'vuetify-confirm'
import i18n from "@/plugins/i18n"

Vue.use(VuetifyConfirm, {
  buttonTrueText: i18n.t("$confirm.ok"),
  buttonFalseText: i18n.t("$confirm.cancel"),
  color: 'warning',
  icon: 'fa fa-exclamation-triangle',
  title: i18n.t("$confirm.title"),
  width: 350,
  property: '$confirm'
})