import InvitationList from "./views/InvitationListView.vue";
import InvitationCreate from "./views/InvitationCreateView.vue";
import InvitationUpdate from "./views/InvitationUpdateView.vue";

export default [
  {
    name: "home",
    path: "",
    component: InvitationList
  },
  {
    name:"create",
    path: "create",
    component: InvitationCreate
  },
  {
    name: "detail",
    path: "detail/:id",
    component: InvitationUpdate,
    props: true
  }
];
