





































import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";
import SearchCompany from "./AddVisitorDialog_CompanyDetail_SearchCompany.vue";

import api, { IF6040Company } from "@/api";
import notifier from "@/stores/notifier";

@Component({
  components: {
    SearchCompany
  }
})
export default class CompanyDetail extends Vue {
  @Prop()
  readonly value!: IF6040Company;
  @Prop()
  readonly editMode!: boolean;
  editing: boolean = this.editMode;
  companySearch: string = this.value ? (this.value.name || "") : ""
  
  get isEditable() {
    return this.editing;
  }
  get address() {
    var result = ""
    if (this.value) {
      if(this.value.street){
        result+=this.value.street
      }
      if(this.value.zip){
        if(result)result+=", "
        result+=this.value.zip        
      }
      if(this.value.city){
        if(result)result+=", "
        result+=this.value.city        
      }
      if(this.value.country){
        if(result)result+=", "
        result+=this.value.country      
      }
    }
    return result;
  }
  get name() {
    return this.value ? this.value.name : "";
  }

  get company() {
    return this.value;
  }
  set company(value: IF6040Company) {
    this.$emit("input", value);
  }
  onChange() {
    this.editing = true;
  }
  onCreate() {
    this.$emit("create");
  }
}
