import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import config from "@/config";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("@/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]*)\/*([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const prefix = matched[1];
      const locale = matched[2];
      const fileContent = locales(key)
      if (!messages[locale]) {
        messages[locale] = {}
      }
      messages[locale] = combine(messages[locale] as LocaleMessages, fileContent, prefix)
    }
  });
  return messages;
}
function combine(target: LocaleMessages, source: object, prefix: string): LocaleMessages {
  if (prefix) {
    if (!target[prefix]) {
      target[prefix] = {}
    }
    target[prefix] = { ...target[prefix], ...source }
    return target
  }
  return { ...target, ...source }
}

const defaultDateTimeFormat = {
  datetime: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false
  },
  time: {
    hour: "numeric",
    minute: "numeric",
    hour12: false
  },
  short: {
    year: "numeric",
    month: "short",
    day: "numeric"
  },
  long: {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  }
};

const dateTimeFormats = {
  en: defaultDateTimeFormat,
  de: defaultDateTimeFormat
};
export class I18n extends VueI18n {
  d(value: number | Date | undefined, key?: any, locale?: any): VueI18n.DateTimeFormatResult {
    if (value) {
      if (typeof value === "string") {
        return super.d(new Date(value), key, locale);
      }
      return super.d(value, key, locale);
    }
    return "";
  }
  html(key: string) {
    var translated = this.t(key).toString()
    
    var pList = translated.split("\n\n").map(p => p.replace(new RegExp("\n", 'g'), "<br>"))
    return "<p>" + pList.join("</p><p>") + "</p>"
  }
  text(key: string) {
    var translated = this.t(key).toString()    
    return translated.replace(new RegExp("\n", 'g'), "<br>")
  }
}

export default new I18n({
  locale: config.locale,
  fallbackLocale: config.fallbackLocale,
  messages: loadLocaleMessages(),
  dateTimeFormats
});
