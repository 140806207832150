

























import { Component, Vue, Prop } from "vue-property-decorator";

import formRules from "@/helpers/formRules";

import visitorStore from "@/module-visitor/stores/visitor";
import notifier from "../../stores/notifier";
import appStore from "../../stores/app";
import router from "@/plugins/router";
import i18n from '@/plugins/i18n';
import LanguageSelectButtons from "@/components/LanguageSelectButtons.vue"

@Component({
  components:{
    LanguageSelectButtons
  }
})
export default class PinView extends Vue {  
  pin: string = "";
  mandatoryRule = [formRules.required];
  loading = false;
  mounted() {
    if(appStore.isAuthenticated){
      appStore.authorize(null);
      visitorStore.reset();
    }
  }
  get textHeadline(){
    return i18n.text("module-visitor.view.pin.welcome")
  }
  get textHowTo(){
    return i18n.text("module-visitor.view.pin.howTo")
  }
  get isValid() {
    return !!this.pin
  }
  async submit() {
    if (this.pin) {
      this.loading = true;
      try {
        await visitorStore.setPin(this.pin);
        this.$router.push({path:"about"})
      } catch (e) {
        console.log(e);
        notifier.addError("module-visitor.view.pin.invalidPinOrInvitation");
      }
      this.loading = false;
    }
  }
}
