





























































































































import { Component, Vue } from "vue-property-decorator";
import notifier from "@/stores/notifier";
import invitation from "../stores/invitation";

import api, { InvitationOverviewDTO, IF6040Host } from "@/api";
import Filter from "@/helpers/filter";
import i18n from "@/plugins/i18n";

@Component
export default class InvitationListView extends Vue {
  pagination:any = {
    page: 1,
    rowsPerPage: 25,
  }
  invitations: InvitationOverviewDTO[] = [];
  progress: boolean = true;
  search: string = "";
  private filter: Filter<InvitationOverviewDTO> = new Filter(
    i => i.subject,
    i => this.getHostName(i),
    i => i.createdBy,
    i => i18n.d(i.visitFrom, "short") + " " + i18n.d(i.visitFrom, "time"),
    i => i18n.d(i.visitTo, "short") + " " + i18n.d(i.visitTo, "time")
  );
  cancelLoadings: string[] = [];
  get filteredInvitations() {
    return this.filter.apply(this.invitations, this.search.toLowerCase());
  }  
  async mounted() {
    try {
      this.invitations = await api.getInvitations();
    } catch (e) {
      console.error(e);
      notifier.addError("module-admin.invitation.msg.canNotLoadInvitations");
    }
    this.progress = false;
  }
  getHostName(invitation: InvitationOverviewDTO): string {
    if (invitation.hostName) {
      return invitation.hostName.firstname + " " + invitation.hostName.lastname;
    }
    return "";
  }
  getClass(invitation: InvitationOverviewDTO) {
    if (invitation.isProfessionVisit) {
      return "row-highlight";
    }
    return "";
  }
  goToCreateInvitation() {
    invitation.reset();
    this.$router.push({ name: "create" });
  }

  async cancel(invitation: InvitationOverviewDTO) {
    var confirmMessage = this.$t(
      "module-admin.invitation.doYouReallyWantToCancelInvitation_subject_",
      { subject: invitation.subject }
    ).toString();
    if (await this.$confirm(confirmMessage)) {
      this.startCanceling(invitation);
      try {
        await api.deleteInvitation({
          invitationId: invitation.invitationId
        });
        this.invitations = this.invitations.filter(
          i => i.invitationId != invitation.invitationId
        );
      } catch (e) {
        console.error(e);
        notifier.addError(
          "module-admin.invitation.msg.invitationCanNotBeCanceled"
        );
      } finally {
        this.endCanceling(invitation);
      }
    }
  }
  startCanceling(invitation: InvitationOverviewDTO) {
    this.cancelLoadings.push(invitation.invitationId!);
  }
  isCanceling(invitation: InvitationOverviewDTO) {
    return this.cancelLoadings.indexOf(invitation.invitationId!) >= 0;
  }
  endCanceling(invitation: InvitationOverviewDTO) {
    var index = this.cancelLoadings.indexOf(invitation.invitationId!);
    if (index >= 0) {
      delete this.cancelLoadings[index];
    }
  }
}
