




































import { Component, Vue, Prop } from "vue-property-decorator";
import * as moment from "moment-timezone";
import appStore from "@/stores/app"
const timeFormat = "HH:mm (hh:mmA)"

@Component
export default class DateTime extends Vue {
  @Prop({ default: new Date() })
  readonly value!: Date;

  @Prop()
  readonly label!: string;

  dateMenuShow: boolean = false;
  get locale(){
    return appStore.state.locale;
  }
  get dateFormatted() {
    return moment
      .utc(this.model)
      .local()
      .format("YYYY-MM-DD");
  }
  set dateFormatted(value: string) {
    var parts = value.split("-");
    this.model = moment
      .utc(this.model)
      .local()
      .date(parseInt(parts[2]))
      .month(parseInt(parts[1])-1)
      .year(parseInt(parts[0]))
      .toDate();
  }
  get timeFormatted() {
    return moment
      .utc(this.model)
      .local()
      .format(timeFormat);
  }
  set timeFormatted(value: string) {
    var parts = value.split(":");
    var date = moment.utc(this.model);
    this.model = date
      .clone()
      .local()
      .second(0)
      .milliseconds(0)
      .minute(parseInt(parts[1]))
      .hour(parseInt(parts[0]))
      .day(date.day())
      .month(date.month())
      .year(date.year())
      .toDate();
  }
  get model() {
    return this.value;
  }
  set model(value: Date) {
    if (moment.utc(value).isValid()) {
      this.$emit("input", value);
    }
  }
  get times(): string[] {
    var time = moment
      .utc()
      .hour(0)
      .minute(0);
    var result: string[] = [];
    for (var i = 0; i < 48; i++) {
      result.push(time.format(timeFormat));
      time.add(30, "minutes");
    }
    return result;
  }
}
