


















import { Component, Vue } from "vue-property-decorator";
import i18n from "@/plugins/i18n"

@Component
export default class About extends Vue {
  get safetyText(){
    return i18n.html("module-visitor.view.about.safetyText")
  }
  get overviewText(){
    return i18n.html("module-visitor.view.about.overviewText")
  }
  get nextStepInfo(){
    return i18n.html("module-visitor.view.about.nextStepInfo")
  }
}
