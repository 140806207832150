

















































import { Component, Vue, Emit } from "vue-property-decorator";

import invitationStore, { Visitor, VisitorStatus } from "@/module-admin/stores/invitation";
import HostCardFeature from "./VisitorGrid_HostCardFeature.vue"
import i18n from "@/plugins/i18n";

@Component({
  components:{
    HostCardFeature
  }
})
export default class VisitorGrid extends Vue {
  get visitors(): Visitor[] {
    return invitationStore.state.visitors;
  }
  get invitationId(){
    return invitationStore.state.id
  }
  getVisitTypeName(visitor: Visitor) {
    var type = invitationStore.state.visitTypes.find(
      t => t.id == visitor.visitTypeId
    );
    if (type && type.name) {
      return type.name;
    }
    return "";
  }
  isVisitTypeDescription(visitor: Visitor) {
    var description = this.getVisitTypeDescription(visitor);
    return description && i18n.t(description).toString() != description;
  }
  getVisitTypeDescription(visitor: Visitor) {
    var type = invitationStore.state.visitTypes.find(
      t => t.id == visitor.visitTypeId
    );
    if (type && type.description) {
      return type.description;
    }
    return "";
  }
  removeVisitor(visitor: Visitor) {
    invitationStore.removeVisitorById(visitor.id);
  }
  getVisitorClass(visitor: Visitor) {
    return visitor.status.toLowerCase()
  }
  getVisitorName(visitor: Visitor) {
    if (visitor.email) {
      return visitor.email;
    }
    return visitor.firstName + " " + visitor.lastName;
  }
}
