import * as api from "./lib/aPI";
import * as msRest from "@azure/ms-rest-js";
import config from "@/config";
import HttpClient from "./HttpClient";

export * from "./lib/models";

var authorizationAccessor: () => string | null = () => { return "" };
const tokenCredentials = {
  signRequest(webResource: msRest.WebResource): Promise<msRest.WebResource> {
    return new Promise(resolve => {
      let authorizationHeader = authorizationAccessor()
      if(authorizationHeader){
        webResource.headers.set("authorization", authorizationHeader || "");
      }
      resolve(webResource);
    });
  }
};
export const httpClient = new HttpClient()
const options = {
  baseUri: config.apiUrl,
  httpClient: httpClient
};

export default new api.API(tokenCredentials, options);

export function setAuthorizationAccessor(func: () => string | null) {
  authorizationAccessor = func
}