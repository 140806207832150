import { render, staticRenderFns } from "./VisitorModule.vue?vue&type=template&id=2c28fff7&"
import script from "./VisitorModule.vue?vue&type=script&lang=ts&"
export * from "./VisitorModule.vue?vue&type=script&lang=ts&"
import style0 from "./VisitorModule.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VStepper } from 'vuetify/lib'
import { VStepperHeader } from 'vuetify/lib'
import { VStepperStep } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
import { VToolbarItems } from 'vuetify/lib'
installComponents(component, {
  VApp,
  VBtn,
  VContainer,
  VContent,
  VDivider,
  VSpacer,
  VStepper,
  VStepperHeader,
  VStepperStep,
  VToolbar,
  VToolbarItems,
})
