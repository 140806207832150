












































import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";
import api, { VisitorDetailDto } from "@/api";
import notifier from "@/stores/notifier";

@Component
export default class SearchVisitor extends Vue {
  loading: boolean = false;
  visitors: VisitorDetailDto[] = [];

  @Prop()
  value!: string;
  @Prop()
  visitor!: VisitorDetailDto;

  get model() {
    return this.visitor || this.value || "";
  }
  set model(value: string | VisitorDetailDto) {
    if (value != null && typeof value == "object") {
      var email = (value as VisitorDetailDto).email || "";
      this.$emit("update:visitor", value);
      this.$emit("input", email);
    } else {
      var selectedEmail = ((value as string) || "").trim();
      this.$emit("update:visitor", null);
      this.$emit("input", selectedEmail);
    }
  }
  get search() {
    return this.value;
  }
  set search(value: string) {
    if (value && value.length > 0) {
      var visitor =
        this.visitor && this.visitor.email == value ? this.visitor : null;
      this.$emit("update:visitor", visitor);
      this.$emit("input", value);
      this.load(value);
    }
  }
  private async load(value: string) {
    this.loading = true;
    try {
      this.visitors = await api.getVisitors({
        inputText: value
      });
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
