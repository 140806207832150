










































































import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator";
import store, {
  VisitType,
  VisitTypeCondition,
  Visitor,
  VisitorStatus
} from "@/module-admin/stores/invitation";
import api, { VisitorCreate, IF6040Company } from "@/api";
import notifier from "@/stores/notifier";
import CompanyDetail from "./AddVisitorDialog_CompanyDetail.vue";
import AddCompanyForm from "./AddVisitorDialog_AddCompanyForm.vue";

@Component({
  components: {
    CompanyDetail,
    AddCompanyForm
  }
})
export default class addAdHocVisitorDialog extends Vue {
  @Prop()
  readonly value!: boolean;
  createVisitorProgress = false;
  firstName = "";
  lastName = "";
  selectedVisitType: VisitType | null = null;
  selectedConditions: string[] = [];
  company: IF6040Company | null = null;
  companySearch = ""
  companyDialog = false

  get visible() {
    return this.value;
  }
  set visible(value: boolean) {
    this.$emit("input", value);
  }
  get visitTypes() {
    return store.state.visitTypes;
  }
  get conditions(): VisitTypeCondition[] {
    if (this.selectedVisitType && this.selectedVisitType.conditions) {
      return this.selectedVisitType.conditions;
    }
    return [];
  }
  get isFormValid() {
    return (
      this.firstName.trim() &&
      this.lastName.trim() &&
      this.company &&
      this.selectedVisitType
    );
  }
  onCreateCompany() {
    this.companyDialog = true;
  }
  onCompanyCreated(company: IF6040Company) {
    this.company = company;
    this.companySearch = company.name || "";
    this.companyDialog = false;
  }
  async submit() {
    if (this.company == null) throw "Missing company";
    if (this.selectedVisitType == null) throw "Missing visitType";
    this.createVisitorProgress = true;
    try {
      var visitorId = await this.createNewVisitor();
      if (visitorId) {
        const visitor: Visitor = {
          id: visitorId || "",
          email: "",
          firstName: this.firstName,
          lastName: this.lastName,
          visitTypeId: this.selectedVisitType.id || "",
          conditions: this.selectedConditions,
          pin: null,
          companyName: this.company.name || "",
          status: VisitorStatus.New,
          isAdHoc: true
        };
        if (store.addVisitor(visitor)) {
          this.reset();
        } else {
          notifier.addError("module-admin.invitation.msg.visitorAlreadyAdded");
        }
      }
    } finally {
      this.createVisitorProgress = false;
    }
  }
  private async createNewVisitor(): Promise<string | undefined> {
    if (this.company) {
      try {
        var visitor: VisitorCreate = {
          email: "",
          companyId: this.company.id || "",
          firstName: this.firstName,
          lastName: this.lastName,
          isAdHoc: true,
        };
        const response = await api.createVisitor({ visitor });
        return response.visitorId;
      } catch (e) {
        console.error(e);
        notifier.addError("module-admin.invitation.msg.visitorCanNotBeCreated");
      }
    }
    return undefined;
  }
  cancel() {
    this.reset();
  }
  private reset() {
    this.visible = false;
    this.firstName = "";
    this.lastName = "";
    this.selectedVisitType = null;
    this.selectedConditions = [];
    this.company = null;
    this.companySearch = ""
    this.companyDialog = false
  }
}
