import { InvitationState, Visitor, VisitType } from './invitation.state';
import { IIndexable, Mutations } from 'pipaslot-vuex-typescript/dist/types';

export default class InvitationMutations extends Mutations<InvitationState> {
  setVisitTypes(types: VisitType[]) {
    this.state.visitTypes = types;
  }
  updateState(state: InvitationState) {
    Object.keys(state).forEach(key => {
      (this.state as IIndexable)[key] = (state as IIndexable)[key];
    });
  }
  addVisitor(visitor: Visitor) {
    //Prevent duplicate insert caused in IE
    if(this.state.visitors.every(v=>v.id != visitor.id)){
      this.state.visitors.push(visitor);
    }else{
      console.warn("Attempt to add duplicate visitor")
    }
  }
  removeVisitor(id: string) {
    this.state.visitors = this.state.visitors.filter(v => v.id != id);
  }
  setSnapshot(snapshot: string) {
    this.state.snapshot = snapshot;
  }
}