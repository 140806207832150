import { Mutations, SyncedStore } from "pipaslot-vuex-typescript";
import api, { setAuthorizationAccessor } from "@/api";
import { getExpiration } from '@/helpers/jwt';
import i18n from "@/plugins/i18n"
import config from "@/config";
import { EventEmitter } from 'events';

export const EVENT_AUTHENTICATION_EXPIRATION = "authenticationExpiration";
export const events: EventEmitter = new EventEmitter()

let tokenRefreshTimer: NodeJS.Timer;
const refreshBeforeExpirationInSeconds = 30;
export enum AuthenticationSource {
    Unknown = 0,
    //Admin = 1, Not supported since we switched to Windows Auth
    Visitor = 2
}
class AppState {
    locale: string = config.locale
    authorizationHeader: string | null = null
    authorizationSource: AuthenticationSource = AuthenticationSource.Unknown
    expiration: Date | null = null
}

class AppMutations extends Mutations<AppState>{
    setLocale(locale: string) {
        this.state.locale = locale;
    }
    setToken(authorizationHeader: string | null, expiration: Date | null, source: AuthenticationSource = AuthenticationSource.Unknown) {
        this.state.authorizationHeader = authorizationHeader;
        this.state.expiration = expiration;
        this.state.authorizationSource = source;
    }
}

class AppStore extends SyncedStore<AppState, AppMutations>{
    get isAuthenticated(): boolean {
        var result = false;
        if (this.state.authorizationHeader != null /*&& this.state.expiration*/) {
            /*
            // In IE is sometimes returned date as string instead of date object. Do nto know why
            let exp = typeof this.state.expiration == "string" ? new Date(this.state.expiration) : this.state.expiration
            result = new Date() < exp;
            */
            result = true
        }
        if(result == false && this.state.authorizationHeader){
            this.mutations.setToken(null, null)
            events.emit(EVENT_AUTHENTICATION_EXPIRATION)
        }
        
        return result
    }
    setLocale(locale: string) {
        i18n.locale = locale;
        this.mutations.setLocale(locale);
    }
    onLoadState() {
        i18n.locale = this.state.locale;
        if (this.isAuthenticated) {
            this.planTokenRefresh();
        }
    }
    authorize(authorizationHeader: string | null, source: AuthenticationSource = AuthenticationSource.Unknown) {
        var expiration = getExpiration(authorizationHeader)
        this.mutations.setToken(authorizationHeader, expiration, source);
        this.planTokenRefresh();
    }
    private planTokenRefresh() {
        if (tokenRefreshTimer) {
            clearTimeout(tokenRefreshTimer);
        }
        if (this.state.expiration && this.isAuthenticated) {
            let differenceMillis = this.state.expiration.getTime() - new Date().getTime();
            let timeout = differenceMillis - (refreshBeforeExpirationInSeconds * 1000);
            if (timeout > 0) {
                tokenRefreshTimer = setTimeout(() => {
                    this.refreshToken();
                }, timeout);
            } else {
                this.authorize(null)
            }
        }
    }
    private refreshToken() {
        api.refreshToken().then(response => {
            const responseToken = response.token || "";
            const expirationDate = getExpiration(responseToken)
            this.mutations.setToken(responseToken, expirationDate, this.state.authorizationSource);
            this.planTokenRefresh();
        }).catch(_ => this.authorize(null));
    }
}
let appStore = new AppStore(new AppState(), new AppMutations())
setAuthorizationAccessor(() => appStore.state.authorizationHeader);
export default appStore
