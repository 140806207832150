import { render, staticRenderFns } from "./InvitationCreateView.vue?vue&type=template&id=cd6dd842&"
import script from "./InvitationCreateView.vue?vue&type=script&lang=ts&"
export * from "./InvitationCreateView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VSpacer,
})
