

























import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";
import api, { VisitorDetailDto } from "@/api";
import notifier from "@/stores/notifier";

@Component
export default class VisitorDetail extends Vue {
  @Prop()
  readonly value!: VisitorDetailDto;

  get isEditable() {
    return this.value && this.value.id == null;
  }
  get email() {
    return this.value && this.value.email ? this.value.email : "";
  }
  set email(value: string) {
    this.$emit("input", { ...this.value, email: value });
  }
  get name() {
    return this.value
      ? (this.value.firstname || "") + " " + (this.value.lastName || "")
      : "";
  }
  onChange() {
    this.$emit("change");
  }
}
