import { render, staticRenderFns } from "./Instructions.vue?vue&type=template&id=04c1e227&scoped=true&"
import script from "./Instructions.vue?vue&type=script&lang=ts&"
export * from "./Instructions.vue?vue&type=script&lang=ts&"
import style0 from "./Instructions.vue?vue&type=style&index=0&id=04c1e227&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c1e227",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCardActions,
  VFlex,
  VIcon,
  VLayout,
  VSpacer,
})
