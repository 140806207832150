






import { Component, Vue, Prop } from "vue-property-decorator";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

@Component
export default class Pdf extends Vue {
  @Prop()
  readonly src?: string;
}
