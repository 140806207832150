










































import { Component, Vue } from "vue-property-decorator";
import api, { APIGetInstructionsOptionalParams } from "@/api";
import visitorStore, { Instruction, Video, Document } from "@/module-visitor/stores/visitor";
import appStore from "@/stores/app"

@Component
export default class modules extends Vue {
  get modules(){
    return visitorStore.state.instructions
  }
  get selectedModule(){
    return visitorStore.state.selectedInstruction
  }
  set selectedModule(instruction: Instruction|null){
    visitorStore.mutations.selectInstruction(instruction);
  }
  async mounted() {
    var any = await visitorStore.loadInstructions(appStore.state.locale)
    if(!any){
      this.skip()
    }
  }
  select(instruction: Instruction) {
    this.selectedModule = instruction;
  }
  isVideo(instruction: Instruction | null) {
    return instruction && (instruction as Video).sources ? true : false;
  }
  isPdf(instruction: Instruction | null) {
    return instruction && (instruction as Document).src ? true : false;
  }
  next() {
    if (this.selectedModule) {
      let pos = this.modules.indexOf(this.selectedModule);
      // Is last
      if (this.modules.length == pos + 1) {
        this.skip();
      } else {
        this.selectedModule = this.modules[pos + 1];
      }
    } else {
      this.skip();
    }
  }
  back() {
    if (this.selectedModule) {
      let pos = this.modules.indexOf(this.selectedModule);
      // Is first
      if (pos <= 0) {
        this.previous();
      } else {
        this.selectedModule = this.modules[pos - 1];
      }
    } else {
      this.previous();
    }
  }
  previous() {
    this.$router.push({ path: "form" });
  }
  skip() {
    this.$router.push({ path: "questionnaire" });
  }
}
