export interface Instruction {
    id: string;
    name: string;
}
export interface Document extends Instruction {
    src: string;
}
export interface Video extends Instruction {
    poster: string | null;
    sources: VideoSource[];
}
export interface VideoSource {
    type: string;
    src: string;
}
export interface QuestionGroup {
    id: string,
    isCompleted: boolean,
    questions: Question[]
}
export interface Question {
    id: string;
    text: string;
    image: string | null;
    answers: Answer[];
    selectedAnswer: string | null;
    isCorrect: boolean | null;
    isAnswered: boolean;
}
export interface Answer {
    id: string;
    text: string;
}

export class VisitorState {
    invitationId: string | null = null
    visitorId: string | null = null
    questionGroups: QuestionGroup[] = []
    instructions: Instruction[] = []
    selectedInstruction: Instruction | null = null
}

export function reduceQuestions(groups: QuestionGroup[]): Question[] {
    var grouped = groups
      .map(g => g.questions)
  
    return grouped.length > 0 ? grouped.reduce((a, b) => a.concat(b)) : []
  }