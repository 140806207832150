export default `<h3>ANWENDUNGSBEREICH UND ZWECK</h3>
<p>
  Bombardier Recreational Products Inc., gemeinsam mit seinen verbundenen Unternehmen und Tochtergesellschaften, („<strong>BRP</strong>“,
  „<strong>wir</strong>“, „<strong>uns</strong>“, „<strong>unser/e</strong>“) ist ein Weltmarktführer bei motorisierten Freizeitfahrzeugen und
  Antriebssystemen.
</p>
<p>
  Wir verpflichten uns zum Schutz Ihrer Privatsphäre und befürworten eine allgemeine Politik der Offenheit über unsere Vorgehensweisen bei der
  Erfassung, Verwendung und Offenlegung Ihrer personenbezogenen Daten (wie nachstehend definiert). Diese Datenschutzrichtlinie gilt für Daten,
  einschließlich personenbezogener Daten, die BRP über Sie erfasst. Der Zweck dieser Datenschutzrichtlinie besteht darin, Sie über die Vorgehensweisen
  von BRP bei der Erfassung, Verwendung und Offenlegung personenbezogener Daten zu informieren, die uns durch den Zugriff auf unsere oder die
  Verwendung von unseren Websites oder Services und verwandte(n) Produkte(n) bereitgestellt oder anderweitig von uns erfasst werden.
</p>
<p>
  In dieser Datenschutzrichtlinie ist darüber hinaus dargelegt, wie Sie mit uns in Kontakt treten können, wenn Sie eine Frage zu den uns über Sie
  vorliegenden personenbezogenen Daten haben oder eine Änderung oder Löschung derartiger personenbezogener Daten wünschen. Wir empfehlen Ihnen
  dringend, diese Datenschutzrichtlinie zu lesen und zum späteren Nachschlagen aufzubewahren.
</p>
<h3>PERSONENBEZOGENE DATEN</h3>
<p>
  In dieser Datenschutzrichtlinie bedeutet „personenbezogene Daten“ Informationen über eine identifizierbare Person.
</p>
<h3>
  <strong>WELCHE ARTEN VON PERSONENBEZOGENEN DATEN ERFASSEN WIR?</strong>
</h3>
<p>
  <strong>Daten, die uns von Betroffenen zur Verfügung gestellt werden</strong>
</p>
<p>
  Wir erfassen Informationen wie z. B. Name, bevorzugte Sprache, Telefonnummer, Anschrift und E-Mail-Adresse, die Sie uns aus freien Stücken zur
  Verfügung stellen; beispielsweise, wenn Sie mehr über BRP-Produkte und -Services erfahren möchten oder wenn Sie sich an unseren Kundenservice
  wenden, um Unterstützung zu Ihren BRP-Produkten oder After-Sales-Service zu erhalten.
</p>
<p><strong>Automatisch erfasste Daten</strong></p>
<p>
  Wir erfassen automatisch Informationen über Sie, z. B. Daten, die durch die Verwendung von Cookies und ähnlichen Technologien erfasst werden, wenn
  Sie auf unsere Websites zugreifen oder über unsere Websites mit uns interagieren. Wir können beispielsweise Informationen über die Art des Geräts
  sammeln, das Sie für den Zugriff auf unsere Websites verwenden, das Betriebssystem und dessen Version, Ihre IP-Adresse, Ihre geografische Region
  (wie durch Ihre IP-Adresse angegeben), den Browsertyp, die von Ihnen aufgerufenen Webseiten auf unseren Websites sowie ob und wie Sie mit den auf
  unseren Websites verfügbaren Inhalten interagieren.
</p>
<p><strong>Aus anderen Quellen erfasste Daten</strong></p>
<p>
  Wir können Informationen über Sie, z. B. demografische Daten, aus anderen Quellen erhalten, darunter auch von Drittanbietern wie
  Händlernetzwerkpartnern und Partnern, mit denen wir Co-Branding-Services anbieten oder gemeinsame Marketingaktivitäten durchführen. Wir können
  Informationen auch von Social-Media-Plattformen wie Facebook und Twitter erhalten, z. B. wenn Sie mit uns auf diesen Plattformen interagieren. Wir
  schützen Daten, die wir von Dritten erhalten, gemäß den in dieser Datenschutzrichtlinie dargelegten Verfahren und befolgen darüber hinaus alle
  zusätzlichen Beschränkungen, die sich aus der Quelle der Daten ergeben.
</p>
<p><strong>Aus Verbraucherinteraktionen mit BRP erfasste Daten</strong></p>
<p>
  Wir haben verschiedene Situationen aufgezeigt, in denen BRP personenbezogene Daten von Kunden erfassen könnte. Kundeninteraktionen mit BRP werden in
  die folgenden Segmente unterteilt:
</p>
<p><strong>Informieren über BRP</strong></p>
<p>
  Bei der Suche nach weiteren Informationen über BRP besuchen Sie ggf. unsere virtuellen oder physischen Standorte. Beispiele hierfür sind das Surfen
  auf unserer Website, Anrufe bei unseren internen Vertriebsmitarbeitern, der Besuch eines Geschäftsstandorts von BRP, das Abonnieren der
  BRP-Newsletter, die Teilnahme an von BRP durchgeführten Wettbewerben und Gewinnspielen oder die Teilnahme an von BRP gesponserten Veranstaltungen.
  Wenn Sie mehr über BRP-Produkte erfahren möchten, können Sie im Rahmen dieser Interaktionen aus freien Stücken personenbezogene Daten wie Ihren
  Namen, Ihre Adresse und Ihre E-Mail-Adresse angeben. Darüber hinaus können wir Informationen über Sie auch online anhand von Cookies oder ähnlichen
  Technologien erfassen, wenn Sie unsere Websites besuchen. Wir verwenden diese Informationen, um Sie über Veranstaltungen und Produkte von BRP zu
  informieren. Darüber hinaus hilft uns die IP-Adresse, einen Einblick in geografische Informationen über die Besucher unserer Websites zu erhalten,
  sodass wir unsere Websites für alle Besucher verbessern können. Die Verarbeitung Ihrer personenbezogenen Daten zu diesen Zwecken liegt in unserem
  berechtigten Interesse.
</p>
<p><strong>Kauf von BRP-Produkten</strong></p>
<p>
  Wenn Sie sich zum Kauf eines BRP-Produkts entscheiden, haben Sie die Wahl zwischen dem Direktkauf bei uns über unsere Websites oder dem Kauf bei
  einem BRP-Händler. Wenn Sie bei uns kaufen, werden Sie gebeten, Informationen bereitzustellen, damit wir Ihren Kauf abschließen können. Wir
  verwenden diese Informationen, um Ihre Transaktion abzuschließen, mit Ihnen über Ihren Kauf zu kommunizieren, Unterstützung im Hinblick auf die
  Lieferung zu leisten, Rücksendungen zu bearbeiten, After-Sales-Service anzubieten und andere Aspekte im Zusammenhang mit dem Kauf Ihrer BRP-Produkte
  abzuwickeln. Die Verarbeitung Ihrer personenbezogenen Daten für diesen Zweck ist zur Erfüllung der Vereinbarung mit Ihnen erforderlich. Beachten Sie
  bitte, dass wir keine Zahlungs- oder Kreditkarteninformationen speichern. Wir verwenden Zahlungsinformationen nur im Zusammenhang mit dem Kauf von
  BRP-Produkten.
</p>
<p><strong>Registrieren Ihrer BRP-Produkte</strong></p>
<p>
  Wenn Sie die Eigentümer-, Rückruf- und Garantieinformationen für Ihr BRP-Produkt registrieren, erfasst BRP Angaben wie Namen, Anschrift und
  E-Mail-Adresse. Diese Angaben sind zur Registrierung Ihres BRP-Produkts erforderlich. Wir erfassen diese Informationen, um den Eigentümer des
  betreffenden BRP-Produkts identifizieren, Rückrufaktionen durchführen und Ihnen Garantie- und After-Sales-Service bieten zu können. Die Verarbeitung
  Ihrer personenbezogenen Daten liegt in unserem berechtigten Interesse und erfolgt im Fall von Rückrufaktionen im Interesse Ihrer Sicherheit.
</p>
<p><strong>Einholen von Hilfe bei BRP</strong></p>
<p>
  Es kann sein, dass Sie sich an unseren Kundenservice wenden möchten, um Unterstützung zu Ihren BRP-Produkten zu erhalten. Damit wir Ihnen den
  bestmöglichen Service bieten können, erheben wir ggf. bestimmte Daten über Sie oder Ihre BRP-Produkte. Wir erfassen diese Informationen, um Ihnen
  den bestmöglichen und effizientesten Kundendienst zu bieten.
</p>
<h3><strong>WIE VERWENDEN WIR IHRE PERSONENBEZOGENEN DATEN?</strong></h3>
<p>
  BRP verwendet die von uns erfassten Daten, um Ihnen die Produkte und Services von BRP anzubieten; dies umfasst Daten zur Optimierung und
  Personalisierung Ihrer Erfahrungen. Darüber hinaus verwenden wir die Daten auch für die Kommunikation mit Ihnen, z. B. für Informationen über Ihr
  Konto, neue BRP-Produkt- oder -Serviceangebote, Sicherheits- und andere Updates. BRP verwendet die Daten für folgende Zwecke:
</p>
<p><strong>Kundendienst</strong></p>
<p>
  Wir verwenden Daten, um auf Kundenanfragen zu reagieren, Produktprobleme zu diagnostizieren, BRP-Produkte von Kunden zu reparieren und andere
  Kundenbetreuungs- und Supportservices anzubieten.
</p>
<p><strong>Produktverbesserung</strong></p>
<p>
  Wir verwenden Daten kontinuierlich zu Forschungszwecken und zur Entwicklung und Verbesserung unserer BRP-Produkte und -Services, darunter die
  Aufrechterhaltung und Verbesserung der Leistung unserer BRP-Produkte und -Services sowie die Entwicklung und Ergänzung neuer Features oder
  Funktionen.
</p>
<p><strong>Sicherheit, Schutz und Konfliktlösung</strong></p>
<p>
  Wir verwenden Daten für den Schutz und die Sicherheit unserer BRP-Produkte und unserer Kunden, um Diebstahl zu erkennen und zu verhindern, um
  Konflikte zu lösen und um unsere Vereinbarungen durchzusetzen.
</p>
<p><strong>Geschäftsvorgänge</strong></p>
<p>
  Wir verwenden Daten zur Entwicklung von aggregierten Analysen und Business Intelligence für den Betrieb, den Schutz, die fundierte
  Entscheidungsfindung und die Berichterstattung über die Unternehmensperformance.
</p>
<p>
  <strong>Kommunikation, Personalisierung, Marketing und Werbung</strong>
</p>
<p>
  Wir verwenden die von uns gesammelten Daten, um unsere Kommunikation durchzuführen und zu personalisieren. Beispielsweise können wir Sie per E-Mail
  oder auf anderen elektronischen Kommunikationswegen kontaktieren, um Sie über neue BRP-Produkte oder -Services zu informieren, um Ihnen einen
  personalisierten Service zu bieten, um Sie über den aktuellen Stand einer Support-Anfrage zu informieren oder um Sie zur Teilnahme an einer Umfrage
  einzuladen. Wir setzen auch Cookies und ähnliche Technologien ein, um Ihnen die für Sie relevanteste BRP-Werbung zu zeigen.
</p>
<p><strong>Vertrieb und After-Sales</strong></p>
<p>
  Unsere Abteilungen für Vertrieb und After-Sales-Services verwenden Ihre Daten, um den Verkauf, die Konfiguration und die Wartung Ihres BRP-Fahrzeugs
  oder Motors durchzuführen. BRP erhält Ihre Kontaktdaten, Details zur Fahrzeugkonfiguration sowie Ihre Vertriebs- und Serviceinformationen bei Kauf,
  Wartung und Reparatur eines Fahrzeugs oder Motors bei uns im Rahmen der Vertriebs- oder Servicevorgänge und verwendet diese Informationen, um Ihnen
  die gewünschten Services bereitzustellen und Sie von Problemen im Zusammenhang mit Ihrem Fahrzeug oder Motors in Kenntnis zu setzen. Diese
  Informationen können von BRP abgerufen werden, um technische oder andere Probleme im Zusammenhang mit der Bereitstellung dieser Services zu beheben.
</p>
<p>
  Die oben genannten Verantwortlichen erhalten ggf. auch begrenzte Fahrzeug-oder Motorinformationen während des Reparaturprozesses, die ausschließlich
  in Übereinstimmung mit den geltenden Gesetzen und Vorschriften verwendet werden.
</p>
<p>
  Zu diesen oben genannten Zwecken kann BRP diese Daten an autorisierte Einzelhändler und andere BRP-Partner und Tochtergesellschaften übertragen.
  Dazu gehören möglicherweise Daten, die Sie für diese Unternehmen bereitgestellt haben oder die durch Ihre Nutzung von BRP-Produkten generiert
  wurden, z. B. Kontaktdetails, Einstellungen, Kundenhistorie, Fahrzeug- oder Motordaten, App-Nutzung und Online-Verhalten.
</p>
<h3><strong>WIE GEBEN WIR IHRE PERSONENBEZOGENEN DATEN WEITER?</strong></h3>
<p>
  Von BRP erfasste personenbezogene Daten können an eine begrenzte Anzahl Dritter übertragen werden. In diesem Fall werden Ihre personenbezogenen
  Daten an diese Vertreter oder Auftragnehmer weitegegeben; dies erfolgt ausschließlich zum Zweck der Erbringung der Leistungen im Auftrag und gemäß
  den Anweisungen von BRP und in Übereinstimmung mit dieser Datenschutzrichtlinie.
</p>
<p>
  Die Daten werden auf sichere Weise und unter Verwendung eines einheitlichen Sicherheitsprotokolls zwischen BRP und den Dritten weitergegeben. Wenn
  BRP die Daten mit anderen Parteien teilt, stellen wir sicher, dass diese Ihre personenbezogenen Daten nur zu diesem Zweck verwenden und dass
  angemessene Sicherheitsvorkehrungen bestehen, um einen Missbrauch dieser Vereinbarung zu verhindern.
</p>
<p>
  Die Arten von Dritten außer den bereits genannten BRP-Unternehmen sind:
</p>
<ul class="specific">
  <li>
    ausgewählte Marketingagenturen, einschließlich Callcentern, die Marketingkampagnen im Auftrag von BRP durchführen;
  </li>
  <li>von BRP autorisierte Händler und Distributoren; und</li>
  <li>Druckdienstleister.</li>
</ul>
<p>
  Bei der Weitergabe von Daten an Dritte können Ihre personenbezogenen Daten in Länder übertragen werden, in denen die Datenschutzgesetze eine
  niedrigere Schutznorm für Ihre personenbezogenen Daten bieten als Ihr Land. Wir gehen beim Schutz Ihrer personenbezogenen Daten mit großer Sorgfalt
  vor und ergreifen angemessene Mechanismen zum Schutz der Daten bei der Übertragung ins Ausland. Wir übertragen Ihre personenbezogenen Daten in
  Übereinstimmung mit den geltenden Datenschutzgesetzen und implementieren angemessene Sicherheitsvorkehrungen, um sicherzustellen, dass Ihre
  personenbezogenen Daten durch alle Dritten geschützt werden, die auf Ihre Daten zugreifen.
</p>
<p>
  Eine Offenlegung bzw. Übermittlung personenbezogener Daten durch BRP an Dritte kann auch im Falle von geplanten oder tatsächlichen Käufen, Verkäufen
  (einschließlich einer Geschäftsauflösung, Liquidierung, Zwangsvollstreckung oder Wiederinbesitznahme), Verpachtungen, Fusionen,
  Unternehmenszusammenschlüssen oder anderen Arten der Übernahme, Veräußerung, Überführung, Übertragung oder Finanzierung aller oder einzelner
  Bereiche von BRP oder jeglicher sonstiger Geschäfts- und Vermögenswerte oder Aktien von BRP oder eines Bereichs von BRP erfolgen, damit Sie auch
  weiterhin die gleichen Produkte und Services vom jeweiligen Dritten erhalten.
</p>
<h3>
  <strong>RECHTLICHE GRUNDLAGEN FÜR DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN DURCH BRP</strong>
</h3>
<p>
  BRP verwendet Ihre oben beschriebenen Daten in Übereinstimmung mit den verschiedenen Datenschutzgesetzen der Länder, in denen wir tätig sind. Dabei
  befolgt BRP die jeweils strengeren Gesetze, um sicherzustellen, dass die verschiedenen Standards weltweit erfüllt werden. BRP beachtet daher die
  folgenden Grundsätze:
</p>
<ul class="specific">
  <li>wenn BRP Ihre Zustimmung erhalten hat;</li>
  <li>wenn zur Erfüllung eines Vertrags erforderlich;</li>
  <li>wenn eine rechtliche Verpflichtung für BRP besteht;</li>
  <li>wenn ein wichtiges Interesse eines Betroffenen besteht;</li>
  <li>
    wenn eine Aufgabe im öffentlichen Interesse durchgeführt wird; und
  </li>
  <li>wenn ein berechtigtes Interesse besteht.</li>
</ul>
<p>
  BRP behält sich das Recht vor, personenbezogene Daten an Dritte weiterzugeben, wenn dies aufgrund eines Gesetzes, einer Verordnung, eines
  Durchsuchungsbefehls, einer gerichtlichen Vorladung oder einer gerichtlichen Verfügung erforderlich oder berechtigt ist.
</p>
<h3><strong>WIE LANGE BEWAHREN WIR IHRE DATEN AUF?</strong></h3>
<p>
  Wir bewahren Ihre Daten nur so lange auf, wie dies für den Zweck ihrer Erfassung und die damit verbundenen zulässigen Zwecke erforderlich ist. Falls
  die Daten für mehrere Zwecke genutzt werden, bewahren wir sie bis zum Ablauf des Zwecks mit der spätesten Frist auf, stellen jedoch jegliche
  Verwendung der Daten für den Zweck mit einer kürzeren Frist ein, sobald diese Frist abgelaufen ist.<br /><br />Ihre Daten werden sicher aufbewahrt,
  und der Zugang ist streng auf die Personen beschränkt, die diese Daten für den jeweiligen Zweck verwenden müssen.
</p>
<p>
  Unsere Aufbewahrungsfristen basieren auf geschäftlichen Anforderungen, und Ihre Daten, die nicht mehr benötigt werden, werden entweder dauerhaft
  anonymisiert oder sicher vernichtet.
</p>
<p>
  Sie haben weiterhin das Recht, Ihre Zustimmung jederzeit zu widerrufen.
</p>
<h3><strong>COOKIES</strong></h3>
<p>
  BRP verwendet Cookies zur Verwaltung unserer Website- und E-Mail-Programme. Wir verwenden keine Cookies, um personenbezogene Daten zu erheben oder
  zu speichern. Ein Cookie ist eine kleine Datei, die beim Besuch einer Website auf Ihrem Computer abgelegt wird. Cookies werden eingesetzt, um
  nicht-personenbezogene Daten zu speichern, die zur Verwaltung Ihrer Erfahrung auf der Website verwendet werden. Dazu zählt beispielsweise die Art
  Ihres Webbrowsers. Ein Cookie kann auch Einstellungen zur Anpassung Ihrer Erfahrung auf der Website speichern. In der Regel können Sie Cookies über
  die Einstellungen in Ihrem Webbrowser steuern.
</p>
<p>
  Cookies helfen uns zu verstehen, wie Verbraucher unsere Websites und E-Mails verwenden, damit wir zukünftig bessere Services entwickeln können.
</p>
<h3><strong>SO SCHÜTZEN WIR IHRE PERSONENBEZOGENEN DATEN</strong></h3>
<p>
  BRP unternimmt wirtschaftlich vertretbare Anstrengungen, um sicherzustellen, dass die personenbezogenen Daten, die wir von Ihnen erfassen, vor
  Verlust und unbefugtem Zugriff geschützt sind. Dieser Schutz bezieht sich auf Daten sowohl in elektronischen Formaten als auch auf Papier. Der
  Zugriff auf Ihre personenbezogenen Daten ist auf Personen beschränkt, deren geschäftlicher Bedarf für den Zugriff dem Grund für die Bereitstellung
  der Daten entspricht. Darüber hinaus nutzt BRP allgemein anerkannte Techniken zur Informationssicherheit, wie z. B. Firewalls und
  Zugangskontrollverfahren, um personenbezogene Daten vor Verlust und unbefugtem Zugriff zu schützen.
</p>
<p><strong>Übertragung personenbezogener Daten</strong></p>
<p>
  Für bestimmte legitime Geschäftszwecke muss BRP personenbezogene Daten von Mitarbeitern, Kunden oder Partnern von BRP möglicherweise an Dritte
  übertragen. Wenn dies nicht gemäß einer rechtlichen Verpflichtung erfolgt, muss in jedem Einzelfall überprüft werden, ob ein Konflikt mit einem
  schützenswerten Interesse des Betroffenen besteht. Bei der Übertragung personenbezogener Daten an einen Dritten müssen die unter „Verarbeitung Ihrer
  personenbezogenen Daten“ festgelegten Bedingungen erfüllt sein. Wenn der Empfänger in einem Bestimmungsland ansässig ist, das nicht dem Ausgangsland
  entspricht, müssen die im Bestimmungsland geltenden Gesetze ein angemessenes Maß des Schutzes personenbezogener Daten gewährleisten, das dem
  Datenschutz laut dieser Richtlinie entspricht. Dies gilt nicht für den Fall, dass die Datenübertragung gemäß einer rechtlichen Verpflichtung
  erfolgt. In jedem Fall muss der Empfänger vor Übertragung der Daten anhand einer Vereinbarung über Auftragsverarbeitung von Daten verpflichtet
  werden, die Daten nur für den angegebenen Zweck zu verwenden. Personenbezogene Daten können an Gerichte oder Behörden übertragen werden, soweit dies
  gemäß den geltenden Datenschutzgesetzen zulässig ist. Im Fall der Übertragung von personenbezogenen Daten an BRP durch Dritte muss sichergestellt
  werden, dass die personenbezogenen Daten rechtmäßig und in Übereinstimmung mit den Datenschutzgesetzen erhoben wurden und dass der vorgesehene
  Verwendungszweck für diese Verarbeitung personenbezogener Daten zulässig ist.
</p>
<p><strong>Übertragung personenbezogener Daten ins Ausland</strong></p>
<p>
  Bei personenbezogenen Daten von EU-Bürgern wird die Übertragung personenbezogener Daten außerhalb der Europäischen Union sorgfältig geprüft, bevor
  die Übertragung erfolgt, um sicherzustellen, dass sie in den laut Datenschutzgrundverordnung (DSGVO) zulässigen Rahmen fällt. Dabei ist auch die
  Beurteilung der Europäischen Kommission zur Angemessenheit der Sicherheitsvorkehrungen für personenbezogene Daten im Empfängerland zu beachten, die
  sich im Laufe der Zeit ändern kann.
</p>
<h3><strong>LINKS ZU WEBSITES VON DRITTEN</strong></h3>
<p>
  Diese Website kann Links zu Websites von Dritten enthalten. Beachten Sie, dass Betreiber von verknüpften Websites möglicherweise ebenfalls Ihre
  personenbezogenen Daten (einschließlich der Informationen, die durch die Verwendung von Cookies generiert wurden) erfassen, wenn Sie einem Link zu
  deren Websites folgen. BRP ist nicht dafür verantwortlich, wie solche Dritten Ihre personenbezogenen Daten erfassen, verwenden oder offenlegen,
  daher ist es wichtig, dass Sie sich mit deren Datenschutzrichtlinien vertraut machen, bevor Sie ihnen Ihre personenbezogenen Daten bereitstellen.
</p>
<h3><strong>SCHUTZ DER PRIVATSPHÄRE VON KINDERN</strong></h3>
<p>
  Die Websites von BRP sind nicht an Kinder gerichtet oder für die Verwendung durch Kinder vorgesehen. Wir erfassen nicht wissentlich personenbezogene
  Daten von Kindern unter 16 Jahren. Kinder unter 16 Jahren sollten sich ohne die Zustimmung eines Elternteils oder Erziehungsberechtigten weder
  registrieren noch BRP personenbezogene Daten zukommen lassen.
</p>
<h3><strong>ÄNDERUNGEN DIESER DATENSCHUTZRICHTLINIE</strong></h3>
<p>
  BRP behält sich das Recht vor, diese Datenschutzrichtlinie jederzeit ohne vorherige Ankündigung zu ändern. Als geltende Fassung der
  Datenschutzrichtlinie gilt die jeweils auf dieser Website veröffentlichte Datenschutzrichtlinie.
</p>
<h3><strong>IHRE RECHTE BEZÜGLICH IHRER DATEN</strong></h3>
<p>
  Unter der Voraussetzung, dass Sie sich BRP gegenüber eindeutig identifizieren, gilt Folgendes:
</p>
<p>
  Sie können BRP fragen, welche personenbezogenen Daten uns über Sie vorliegen, und Sie können von uns verlangen, diese Daten zu berichtigen, wenn sie
  fehlerhaft sind.
</p>
<p>
  Sie können die Löschung der Daten verlangen und bei uns eine Kopie der Daten anfordern.
</p>
<p>
  Sie können von uns verlangen, Ihre personenbezogenen Daten nicht mehr zu verwenden – am einfachsten geht das, indem Sie Ihre Zustimmung widerrufen;
  das können Sie jederzeit durch Klicken auf den Link „Abbestellen“ am Ende eines Newsletters oder per E-Mail, Schreiben oder Anruf an die unten
  angegebenen Kontaktdaten tun.
</p>
<p>
  Sie können uns helfen, die Richtigkeit Ihrer personenbezogenen Daten aufrecht zu erhalten, indem Sie uns über Änderungen dieser Daten informieren.
  Falls Sie Fragen zu der Datenschutzrichtlinie von BRP oder eine Beschwerde über unsere Nutzung Ihrer personenbezogenen Daten haben, können Sie uns
  auf jedem der folgenden Wege kontaktieren:
</p>
<p>
  <strong>E-MAIL: </strong
  ><a
    href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#112;&#x72;&#x69;&#x76;&#x61;&#99;&#121;&#x6F;&#x66;&#102;&#x69;&#99;&#101;&#x72;&#64;&#x62;&#x72;&#112;&#x2E;&#99;&#111;&#x6D;"
    >&#112;&#x72;&#x69;&#x76;&#x61;&#99;&#121;&#x6F;&#x66;&#102;&#x69;&#99;&#101;&#x72;&#64;&#x62;&#x72;&#112;&#x2E;&#99;&#111;&#x6D;</a
  >
</p>
<p>
  <strong>POST: <br /></strong>Bombardier Recreational Products Inc.<br />Attention: Legal Services <br />726, St-Joseph Street<br />Valcourt,
  Quebec<br />J0E 2L0<br />Kanada
</p>
<p>
  Wir sind bestrebt, mit Ihnen zusammenzuarbeiten, um bei jeglichen Beschwerden oder Anliegen, die Sie hinsichtlich der Nutzung Ihrer
  personenbezogenen Daten haben, eine faire Lösung herbeizuführen. Wenn Sie jedoch der Ansicht sein sollten, dass wir Ihnen bei Ihrer Beschwerde oder
  Ihrem Anliegen nicht helfen konnten, haben Sie das Recht, eine Beschwerde bei der Datenschutzbehörde in Ihrem Land (falls zutreffend) oder dem
  zuständigen Aufsichtsgremium einzureichen.
</p>`;