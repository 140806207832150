






























































import { Component, Vue } from "vue-property-decorator";
import notifier from "../../stores/notifier";
import rootStore from "@/plugins/store";
import visitorStore, {
  Question,
  Answer
} from "@/module-visitor/stores/visitor";
import Stepper from "@/components/Stepper.vue";
import appStore from "@/stores/app";
import i18n from "../../plugins/i18n";

class Group {
  constructor(public startId: number, public items: GroupItem[]) {}

  get correct(): boolean {
    return this.items.every(i => i.correct === true);
  }
  get incorrect(): boolean {
    return this.items.some(i => i.correct === false);
  }
  get count(): number {
    return this.items.length;
  }
  get completed() {
    return this.items.filter(i => i.correct !== null).map(i => i.correct);
  }
}
interface GroupItem {
  number: number;
  correct: boolean | null;
}

interface Group {
  id: string;
  min: number;
  count: number;
  complete: boolean;
}
@Component({
  components: {
    Stepper
  }
})
export default class Questionnaire extends Vue {
  processing = false;
  get groups() {
    var counter = 1;
    return visitorStore.state.questionGroups.map(g => {
      var start = counter;
      var items = g.questions.map(
        q =>
          <GroupItem>{
            number: counter++,
            correct: q.isCorrect
          }
      );
      var group = new Group(start, items);
      return group;
    });
  }
  get questions() {
    return visitorStore.questions;
  }
  async mounted() {
    var any = await visitorStore.loadQuestions(appStore.state.locale);
    if (!any || this.canEvaluate) {
      await this.evaluate();
    }
  }
  get activeQuestion() {
    return this.questions.find(q => q.isAnswered == false)
  }
  get totalQuestions() {
    return this.questions.length;
  }
  get canGoNext() {
    return this.questions.some(q => q.isAnswered == false);
  }
  get canEvaluate() {
    return this.questions.every(q => q.isCorrect !== null);
  }
  select(question: Question, answer: Answer) {
    if (question.isCorrect == null) {
      visitorStore.mutations.setAnswer(question.id, answer.id);
    }
  }
  async next(question: Question) {
    if (!question.selectedAnswer) {
      return;
    }
    this.processing = true;
    try {
      await visitorStore.evaluateQuestion(question.id, question.selectedAnswer);
      this.processing = false;
    } catch (e) {
      console.error(e);
      //TODO add error handling
      this.processing = false;
    }
    visitorStore.mutations.markAnswered(question.id);
    if (this.canEvaluate) {
      this.evaluate();
    }
  }
  async goBack() {
    const message = i18n.t("module-visitor.view.questionnaire.confirmUnfinisherConditionWillBeLost").toString();
    if (await this.$confirm(message)) {
      this.$router.push({ path: "instructions" });
    }
  }
  async evaluate() {
    this.processing = true;
    try {
      if (await visitorStore.evaluateQuestionnaire()) {
        if(visitorStore.questions.length > 0){
          notifier.addSuccess("module-visitor.view.questionnaire.msgSuccess");
        }
        this.$router.push({
          path: "complete",
          query: this.$route.query
        });
        rootStore.clear(false);
      } else {
        notifier.addError("module-visitor.view.questionnaire.msgFail");
        visitorStore.resetWrongAnswers();
      }
    } finally {
      this.processing = false;
    }
  }
}
