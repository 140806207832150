import { Mutations } from 'pipaslot-vuex-typescript';
import { VisitorState, QuestionGroup, Instruction, reduceQuestions } from './visitor.state';

function shuffle<T>(a: Array<T>): Array<T> {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export default class VisitorMutations extends Mutations<VisitorState> {
  reset() {
    this.state.visitorId = null;
    this.state.invitationId = null;
    this.state.questionGroups = [];
    this.state.instructions = []
    this.state.selectedInstruction = null
  }
  setVisitor(invitationId: string | null, visitorId: string | null) {
    this.state.invitationId = invitationId
    this.state.visitorId = visitorId
  }
  setQuestionGroups(questionGroups: QuestionGroup[]) {
    this.state.questionGroups = questionGroups
  }
  setInstructions(instructions: Instruction[]) {
    this.state.instructions = instructions
    this.state.selectedInstruction = instructions.length > 0 ? instructions[0] : null
  }
  selectInstruction(instruction: Instruction | null) {
    this.state.selectedInstruction = instruction
  }
  setAnswer(questionId: string, answerId: string) {
    var question = reduceQuestions(this.state.questionGroups).find(q => q.id == questionId)
    if (question) {
      question.selectedAnswer = answerId
    } else {
      console.error(`Question with id "${questionId}" not found`)
    }
  }
  setIsCorrectAnswer(questionId: string, isCorrect: boolean) {
    var question = reduceQuestions(this.state.questionGroups).find(q => q.id == questionId)
    if (question) {
      question.isCorrect = isCorrect
    } else {
      console.error(`Question with id "${questionId}" not found`)
    }
  }
  setGroupCompleted(groupId: string, completed: boolean) {
    var group = this.state.questionGroups.find(g => g.id == groupId)
    if (group) {
      group.isCompleted = completed
    }
  }
  resetWrongAnswers() {
    let newGroups = this.state.questionGroups.map(group => {
      if (group.questions.some(q => q.isCorrect === false)) {
        group.questions.forEach(question => {
          question.isCorrect = null;
          question.selectedAnswer = null;
          question.answers = shuffle(question.answers)
          question.isAnswered = false
        });
        group.questions = shuffle(group.questions)
      }
      return group;
    });
    this.setQuestionGroups(newGroups)
  }
  markAnswered(questionId:string){
    var question = reduceQuestions(this.state.questionGroups).find(q => q.id == questionId)
    if (question) {
      question.isAnswered = true
    } else {
      console.error(`Question with id "${questionId}" not found`)
    }
  }
}