import { Mutations, Store } from "pipaslot-vuex-typescript";
import config from "@/config"

var defaultTimeouts = config.messageTimeouts || {
  info: 10,
  error: null,
  success: 5,
  warning: 10,
}

var messageCounter = 1;
export type MessageParams = null | Array<string> | Object;
export class Message {
  id: number;
  amount: number = 1;
  text: string;
  params: MessageParams = null;
  type: string;
  timeout: NodeJS.Timeout | null = null;
  // Datetime milisecond
  dismissTime: number | null = null;
  constructor(text: string, type: string = "info", params: MessageParams = null) {
    this.id = messageCounter++;
    this.text = text;
    this.type = type;
    this.params = params;
  }
}

class NotifierState {
  messages: Message[] = [];
}
class NotifierMutations extends Mutations<NotifierState> {
  addMessage(message: Message, dismissTime: number | null = null, timeout: NodeJS.Timeout | null = null) {
    let existing = this.state.messages.find(m => m.text == message.text && m.type == message.type && m.params == message.params);
    if (existing) {
      existing.amount++;
    } else {
      this.state.messages.push(message);
      existing = message;
    }
    existing.dismissTime = dismissTime;
    existing.timeout = timeout;
  }

  removeMessage(id: number) {
    this.state.messages = this.state.messages.filter(m => m.id != id);
  }
}

export class NotifierStore extends Store<NotifierState, NotifierMutations> {
  addInfo(text: string, params: MessageParams = null, dismiss: number | null = null) {
    this.add(text, "info", params, dismiss || defaultTimeouts.info);
  }
  addError(text: string, params: MessageParams = null, dismiss: number | null = null) {
    this.add(text, "error", params, dismiss || defaultTimeouts.error);
  }
  addWarning(text: string, params: MessageParams = null, dismiss: number | null = null) {
    this.add(text, "warning", params, dismiss || defaultTimeouts.warning);
  }
  addSuccess(text: string, params: MessageParams = null, dismiss: number | null = null) {
    this.add(text, "success", params, dismiss || defaultTimeouts.success);
  }
  add(text: string, type: string = "info", params: MessageParams = null, dismiss: number | null = null) {
    let existing = this.state.messages.find(m => m.text == text && m.type == type && m.params == params) || new Message(text, type, params);
    let timeout = null;
    let dismissTime = null;
    if (existing.timeout) {
      clearTimeout(existing.timeout);
    }
    if (dismiss) {
      timeout = setTimeout(() => {
        this.remove(existing.id);
      }, 1000 * dismiss);
      dismissTime = new Date().getMilliseconds() + 1000 * dismiss;
    }
    this.mutations.addMessage(existing, dismissTime, timeout);
  }
  remove(id: number) {
    this.mutations.removeMessage(id);
  }
  get active() {
    let now = new Date().getMilliseconds();
    return this.state.messages.filter(m => m.dismissTime == null || m.dismissTime >= now);
  }
}

export default new NotifierStore(new NotifierState(), new NotifierMutations());
