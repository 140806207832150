import { render, staticRenderFns } from "./InvitationListView.vue?vue&type=template&id=751333e4&"
import script from "./InvitationListView.vue?vue&type=script&lang=ts&"
export * from "./InvitationListView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VDataTable,
  VFlex,
  VIcon,
  VLayout,
  VTextField,
})
