






















import { Component, Vue, Prop } from "vue-property-decorator";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";

@Component
export default class VideoPlayer extends Vue {
  @Prop()
  readonly poster?: string;
  @Prop()
  readonly sources?: videojs.Tech.SourceObject[];
  @Prop()
  readonly externalControls?: boolean;

  private player: videojs.Player | null = null;
  private time: number = 0;
  private duration: number = 0;
  get playerOptions(): videojs.PlayerOptions {
    var opts: videojs.PlayerOptions = {
      controls: true,
      autoplay: false,
      muted: true,
      poster: this.poster,
      sources: this.sources
    };
    return opts;
  }
  get isSource() {
    return this.sources && this.sources.length > 0;
  }
  mounted() {
    if (this.isSource) {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.playerOptions,
        this.onReady
      );
    }
  }
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
  private onReady() {
    if (this.player) {
      this.player.on("loadedmetadata", () => {
        this.duration = Math.round(this.player!.duration());
      });
      this.player.on("timeupdate", () => {
        if (this.player) {
          this.time = Math.round(this.player.currentTime());
        }
      });
    }
  }
  private playPause() {
    if (this.player && this.externalControls) {
      if (this.player.paused()) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }
  }
  private mute() {
    if (this.player) {
      this.player.muted(!this.player.muted());
    }
  }
  private goBack(seconds: number) {
    if (this.player) {
      var time = this.player.currentTime();
      time -= seconds;
      if (time < 0) {
        time = 0;
      }
      this.player.currentTime(time);
    }
  }
  private formatTime(sec_num: number) {
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    return (
      (hours > 0 ? this.formatTimePart(hours) + ":" : "") +
      (minutes > 0 ? this.formatTimePart(minutes) + ":" : "") +
      this.formatTimePart(seconds)
    );
  }
  private formatTimePart(time: number): string {
    return time < 10 ? "0" + time : time.toString();
  }
}
