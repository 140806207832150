

















































































import { Component, Vue } from "vue-property-decorator";

import Pin from "./views/PinView.vue";
import appStore, { AuthenticationSource } from "@/stores/app";
import rootStore from "@/plugins/store";

// import LanguageSelect from "@/components/LanguageSelect.vue";
// import ToolbarLanguageSelect from "@/components/ToolbarLanguageSelect.vue";
import Notifier from "@/components/Notifier.vue";

@Component({
  components: {
    Pin,
    // LanguageSelect,
    // ToolbarLanguageSelect,
    Notifier
  }
})
export default class App extends Vue {
  get step() {
    return this.$route.meta.step || 1
  }
  set step(step:number){
    // Can not change steps directly
  }
  get isLogo(){
    return this.$route.meta.logo || false
  }
  menuDialog = false;
  get isAuthenticated() {
    return (
      appStore.isAuthenticated &&
      appStore.state.authorizationSource == AuthenticationSource.Visitor
    );
  }
  logout() {
    rootStore.clear(false);
    this.menuDialog = false;
    this.$router.push("login");
  }

  mounted() {
    document.addEventListener('scroll', (event) => {
      const el = document.getElementById('navigation');
      const elSt = document.getElementById('stepper');
      if (el && el !== undefined) {
        if (window.pageYOffset > 104) {
          el.classList.add('nav-toolbar--fixed');
        } else {
          el.classList.remove('nav-toolbar--fixed');
        }
      }

      if (elSt && elSt !== undefined) {
        if (window.pageYOffset > 104) {
          elSt.classList.add('stepper--fixed');
        } else {
          elSt.classList.remove('stepper--fixed');
        }
      }
    })
  }
}
