import { render, staticRenderFns } from "./LanguageSelect.vue?vue&type=template&id=462f8e10&"
import script from "./LanguageSelect.vue?vue&type=script&lang=ts&"
export * from "./LanguageSelect.vue?vue&type=script&lang=ts&"
import style0 from "./LanguageSelect.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VIcon,
  VList,
  VListTile,
  VMenu,
})
