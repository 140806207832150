















































import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";
import api, { IF6040Company } from "@/api";
import notifier from "@/stores/notifier";

@Component
export default class SearchCompany extends Vue {
  loading: boolean = false;
  companies: IF6040Company[] = [];

  @Prop()
  value!: string;
  @Prop()
  company!: IF6040Company;

  get model() {
    return this.company || this.search;
  }
  set model(value: string | IF6040Company) {
    if (typeof value == "object") {
      var name = (value as IF6040Company).name || "";
      this.$emit("update:company", value);
      this.$emit("input", name);
    } else {
      var selectedName = ((value as string) || "").trim();
      this.$emit("update:company", value);
      this.$emit("input", selectedName);
    }
  }
  get search() {
    return this.value;
  }
  set search(value: string) {
    if (value && value.length > 0) {
      var company =
        this.company && this.company.name == value ? this.company : null;
      this.$emit("update:company", company);
      this.$emit("input", value);
      this.load(value);
    }else{
      this.$emit("update:company", null);
      this.$emit("input", "");
    }
  }
  private async load(value: string) {
    this.loading = true;
    try {
      this.companies = await api.getCompanies({
        inputText: value
      });
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
