























import { Component, Vue, Prop } from "vue-property-decorator";

import InvitationForm from "../templates/InvitationForm.vue";

import invitationStore from "../stores/invitation";
import notifier from "@/stores/notifier";

@Component({
  components: {
    InvitationForm
  }
})
export default class UpdateInvitationView extends Vue {
  @Prop()
  readonly id!: string;

  loading = false;
  get isValid() {
    return invitationStore.isValid;
  }

  async mounted() {
    try {
      if (invitationStore.isDirtyState()) {
        var confirmMessage = this.$t(
          "general.confirmDiscardUnsavedChanges"
        ).toString();
        if (!(await this.$confirm(confirmMessage))) {
          invitationStore.LoadVisitTypes();
          return;
        }
      }
      invitationStore.load(this.id);
    } catch (e) {
      console.log(e);
    }
  }
  back() {
    invitationStore.reset();
    this.$router.push({ name: "home" });
  }
  async submit() {
    this.loading = true;
    try {
      await invitationStore.updateInvitation();
      notifier.addSuccess("module-admin.invitation.msg.invitationWasUpdated");
      this.$router.push({ name: "home" });
    } catch (e) {
      notifier.addError(
        "module-admin.invitation.msg.invitationCanNotBeUpdated"
      );
    }
    this.loading = false;
  }
}
