























import { Component, Vue } from "vue-property-decorator";

import api, { VisitorUpdate } from "@/api";
import visitorStore from "@/module-visitor/stores/visitor";
import notifier from "../../stores/notifier";

@Component
export default class VisitorForm extends Vue {
  title: string = "";
  firstName: string = "";
  surname: string = "";
  birthDate: Date | null = null;
  phone: string = "";
  licencePlate: string = "";
  company: string = "";
  loading = true;
  processing = false;
  isLicencePlateMandatory = false
  async mounted() {
    this.loading = true;
    if (visitorStore.state.visitorId && visitorStore.state.invitationId) {
      try {
        var visitor = await api.getVisitor({
          visitorId: visitorStore.state.visitorId,
          invitationId: visitorStore.state.invitationId
        });
        this.title = visitor.title || "";
        this.firstName = visitor.firstname || "";
        this.surname = visitor.lastName || "";
        this.birthDate = visitor.birthDate || null;
        this.company = visitor.companyName || "";
        this.phone = visitor.phoneNumber || "";
        this.licencePlate = visitor.licensePlate || "";
        this.isLicencePlateMandatory = visitor.isLicencePlateMandatory || false
      } catch (e) {
        console.log(e);
      }
    }
    this.loading = false;
  }
  get isValid() {
    return (
      visitorStore.state.visitorId &&
      visitorStore.state.invitationId &&
      this.firstName.trim() &&
      this.surname.trim() &&
      this.birthDate &&
      (this.licencePlate.trim() || !this.isLicencePlateMandatory)
    );
  }
  async update() {
    if (this.isValid) {
      this.processing = true;
      let visitor: VisitorUpdate = {
        visitorId: visitorStore.state.visitorId!,
        invitationId: visitorStore.state.invitationId!,
        title: this.title.trim(),
        firstname: this.firstName.trim(),
        lastName: this.surname.trim(),
        birthDate: this.birthDate!,
        phoneNumber: this.phone.trim(),
        licensePlate: this.licencePlate.trim()
      };
      try {
        await api.updateVisitor({
          visitor: visitor
        });

        this.$router.push({ path: "instructions" });
      } catch (e) {
        console.log(e);
      }
      this.processing = false;
    }
  }
}
