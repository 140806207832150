






























import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import * as moment from "moment-timezone";
import i18n from "@/plugins/i18n";

@Component
export default class DateInput extends Vue {
  @Prop()
  readonly value!: Date;

  @Prop()
  readonly label!: string;

  years: number[] = this.createRange(1920, new Date().getFullYear()).reverse();
  months: number[] = this.createRange(1, 12);
  get days() {
    return this.createRange(1, this.lastDay);
  }
  get lastDay() {
    if (this.year && this.month) {
      return this.getDaysInMonth(this.year, this.month);
    }
    return 31;
  }

  year: number | null = null;
  month: number | null = null;
  day: number | null = null;

  mounted() {
    if (this.value) {
      this.setDate(this.value);
    }
  }
  @Watch("value")
  onValueChanged(value: Date | null, oldVal: Date | null) {
    if (value) {
      this.setDate(value);
    }
  }
  onChange() {
    if (this.day && this.day < 1) this.day = 1;
    if (this.day && this.day > this.lastDay) this.day = this.lastDay;
    if (this.year && this.month && this.day) {
      let date = moment
        .utc()
        .year(this.year)
        .month(this.month - 1)
        .date(this.day)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toDate();
      this.$emit("input", date);
      //Set date for cases when date is not valid for selected month
      this.setDate(date);
    } else {
      this.$emit("input", null);
    }
  }
  private setDate(value: Date) {
    this.year = value.getFullYear();
    this.month = value.getMonth() + 1;
    this.day = value.getDate();
  }
  private getDaysInMonth(year: number, month: number): number {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate();
    // Here January is 0 based
    // return new Date(year, month+1, 0).getDate();
  }

  createRange(start: number, end: number): number[] {
    return Array.from({ length: end + 1 - start }, (v, k) => k + start);
  }
}
