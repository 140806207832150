import i18n from "@/plugins/i18n";
import * as msRest from "@azure/ms-rest-js";
import notifier from "@/stores/notifier";
import { EventEmitter } from 'events';

export const EVENT_LOGOUT = "logout";

export default class HttpClient extends msRest.DefaultHttpClient {
    public events: EventEmitter = new EventEmitter()
  public async sendRequest(httpRequest: msRest.WebResource): Promise<msRest.HttpOperationResponse> {
    var response = await this.sendRequestInParent(httpRequest);
    if (response.status !== 200 && response.status !== 204) {
      this.onRequestFailed(response)
    }
    return response;
  }
  
  private async sendRequestInParent(httpRequest: msRest.WebResource): Promise<msRest.HttpOperationResponse> {
    try {
      return await super.sendRequest(httpRequest);
    } catch (e) {
      //Connection failed
      notifier.addError("responseErrorType.connectionFailed");
      throw e;
    }
  }

  private onRequestFailed(response: msRest.HttpOperationResponse) {
    var body = response.bodyAsText ? JSON.parse(response.bodyAsText) : null
    if (response.status == 422 && body) {
      this.processValidation(body)
      return;
    }
    if (response.status == 401 && !body) {
      //Notify user for case that user is restarted and IF6040 login session was closed
      notifier.addError("responseErrorType.connectionLost");
      this.events.emit(EVENT_LOGOUT)
      return;
    }

    if (body && body.ErrorType && body.ErrorType != 0) {
      var key = "responseErrorType." + body.ErrorType;
      var translation = i18n.t(key);
      if (translation != key) {        
        notifier.addError(translation.toString());
        throw translation;
      }
      if (body.ResultValue) {
        throw body.ResultValue;
      }
      throw "Error: " + response.parsedBody.ErrorType;
    } else if (response.status >= 400 && body && body.ResultValue) {
      //Return message from API
      throw response.parsedBody.ResultValue;
    } else if (response.status == 500) {
      throw "Unknown server error"
    } else {
      console.log("Unhandled response", response)
    }
  }

  private processValidation(body: any | null) {
    if (body && body.ResultValue && typeof Array.isArray(body.ResultValue)) {
      (body.ResultValue as Array<ValidationResult>).forEach(item => {
        if (item.Description) {
          notifier.addWarning(item.Description)
        }
      });
    }
  }
}
interface ValidationResult{
  Name:string
  Description:string
}
