import { SyncedStore } from "pipaslot-vuex-typescript";
import api, { InvitationCreate, InvitationUpdate, InvitationDetailInvitedVisitor, GetInvitationResponse, IF6040Host } from "@/api";
import { IIndexable } from "pipaslot-vuex-typescript/dist/types";
import { InvitationState, Visitor, VisitType, VisitTypeCondition, VisitorStatus } from "./invitation.state"
import InvitationMutations from './invitation.mutations';

export * from "./invitation.state"

interface IUpdateDelegate {
  (state: InvitationState): void;
}

export class InvitationStore extends SyncedStore<InvitationState, InvitationMutations> {
  reset(reloadData: boolean = true) {
    this.mutations.updateState(new InvitationState());
    if(reloadData){
      this.LoadVisitTypes();
    }
  }
  update(delegate: IUpdateDelegate) {
    let stateToModify = { ...this.state };
    delegate(stateToModify);
    //Extract only changed values
    let stateChange: any = {};
    Object.keys(stateToModify).forEach(key => {
      let newValue = (stateToModify as IIndexable)[key];
      if ((this.state as IIndexable)[key] != newValue) {
        (stateChange[key] as IIndexable) = newValue;
      }
    });
    delegate(stateToModify);
    this.mutations.updateState(stateChange);
  }
  addVisitor(visitor: Visitor): boolean {
    if (!visitor.id || this.state.visitors.find(v => v.id == visitor.id) == null) {
      this.mutations.addVisitor(visitor);
      return true;
    }
    console.warn("Visitor already exist");
    return false;
  }
  removeVisitorById(id: string) {
    let visitor = this.state.visitors.find(v => v.id == id);
    if (visitor) {
      this.mutations.removeVisitor(id);
    } else {
      console.warn("Visitor does not exist");
    }
  }
  get isValid() {
    return this.state.host && this.state.subject && this.state.durationFrom && this.state.durationTo && this.state.visitors.length > 0;
  }
  async LoadVisitTypes() {
    if (this.state.visitTypes.length == 0) {
      let types = await api.getConditions()
      var extendedTypes = types.map(
        type =>
          <VisitType>{
            id: type.id,
            name: `enum.visitType.${type.name}.name`,
            description: `enum.visitType.${type.name}.description`,
            conditions: (type.visitConditions || []).map(
              con =>
                <VisitTypeCondition>{
                  id: con.conditionId,
                  name: `enum.visitType.${type.name}.conditions.${con.name}`
                }
            )
          }
      );
      this.mutations.setVisitTypes(extendedTypes);
    }
  }
  async createInvitation() {
    if (this.state.host) {
      try {
        var invitation: InvitationCreate = {
          hostId: this.state.host.id!,
          from: this.state.durationFrom,
          to: this.state.durationTo,
          subject: this.state.subject,
          hasMailNotification: this.state.mailNotification,
          notes: this.state.notes,
          isMeetingRoom: this.state.isMeetingRoom,
          isPictureAllowance: this.state.isPictureAllowance,
          meetingRoomNote: this.state.meetingRoomNote,
          nrOfMealsInCanteen: this.state.nrOfMealsInCanteen,
          nrOfVisitorsInGroupVisit: this.state.nrOfVisitorsInGroupVisit,
          invitedVisitorsWithCondition: this.state.visitors.map(v => ({
            visitorId: v.id,
            visitTypeId: v.visitTypeId,
            conditions: v.conditions.map(c => ({
              id: c
            }))
          }))
        }
        await api.createInvitation({ invitation });
        this.reset();
      } catch (e) {
        console.error(e)
        throw "Invitation can't be create";
      }
    } else {
      throw "Invalid data";
    }
  }
  async updateInvitation() {
    if (this.state.host) {
      try {
        var invitation: InvitationUpdate = {
          id: this.state.id!,
          hostId: this.state.host.id!,
          from: this.state.durationFrom,
          to: this.state.durationTo,
          subject: this.state.subject,
          hasMailNotification: this.state.mailNotification,
          notes: this.state.notes,
          isMeetingRoom: this.state.isMeetingRoom,
          isPictureAllowance: this.state.isPictureAllowance,
          meetingRoomNote: this.state.meetingRoomNote,
          nrOfMealsInCanteen: this.state.nrOfMealsInCanteen,
          nrOfVisitorsInGroupVisit: this.state.nrOfVisitorsInGroupVisit,
          invitedVisitorsWithCondition: this.state.visitors.map(v => ({
            visitorId: v.id,
            visitTypeId: v.visitTypeId,
            conditions: v.conditions.map(c => ({
              id: c
            }))
          }))
        }
        await api.updateInvitation({ invitation });
        this.reset();
      } catch (e) {
        console.error(e)
        throw "Invitation can't be updated";
      }
    } else {
      throw "Invalid data";
    }
  }
  async load(id: string) {
    this.reset();
    const invitation = await api.getInvitation({ invitationGuid: id });
    if (invitation) {
      this.update(s => this.modifyState(s, invitation));
      var newSnapshot = this.createSnapshot()
      this.mutations.setSnapshot(newSnapshot)
    }
  }
  isDirtyState(): boolean {
    if (!this.state.snapshot) {
      return false;
    }
    var currentSnapshot = this.createSnapshot()
    return this.state.snapshot != currentSnapshot;
  }

  private createSnapshot() {
    var clonedState = JSON.parse(JSON.stringify(this.state)) as InvitationState
    clonedState.snapshot = "";
    clonedState.visitTypes = [];
    return JSON.stringify(clonedState)
  }

  private modifyState(state: InvitationState, invitation: GetInvitationResponse) {
    state.id = invitation.invitationId || null;
    state.host = this.mapHost(invitation);
    state.subject = invitation.subject || "";
    state.durationFrom = invitation.visitFrom!;
    state.durationTo = invitation.visitTo!;
    state.mailNotification = !!invitation.hasMailNotification;
    state.notes = invitation.notes || "";
    state.isMeetingRoom = invitation.isMeetingRoom || false;
    state.meetingRoomNote = invitation.meetingRoomNote || "";
    state.visitors = invitation.invitedVisitors!.map(this.mapVisitor);
    state.nrOfMealsInCanteen = invitation.nrOfMealsInCanteen || 0
    state.nrOfVisitorsInGroupVisit = invitation.nrOfVisitorsInGroupVisit || 0
    state.isPictureAllowance = invitation.isPictureAllowance || false
  }
  private mapHost(invitation: GetInvitationResponse): IF6040Host {
    const host = invitation.host!;
    var ifHost: IF6040Host = {
      firstname: host.firstname,
      lastname: host.lastname,
      email: host.email,
      id: host.id,
      personNumber: host.personNumber
    }
    return ifHost
  }
  private mapVisitor(v: InvitationDetailInvitedVisitor): Visitor {
    var visitor: Visitor = {
      id: v.visitorId!,
      email: v.email || "",
      firstName: v.firstname || "",
      lastName: v.lastName || "",
      visitTypeId: v.visitTypeId || "",
      conditions: v.conditions ? v.conditions.map(c => c.conditionId || "") : [],
      pin: v.pin || "",
      companyName: v.companyName || "",
      status: v.status == VisitorStatus.Deregistered
        ? VisitorStatus.Deregistered
        : v.status == VisitorStatus.Invited
          ? VisitorStatus.Invited
          : v.status == VisitorStatus.Preregistered
            ? VisitorStatus.Preregistered
            : v.status == VisitorStatus.Registered
              ? VisitorStatus.Registered
              : VisitorStatus.New,
      isAdHoc: false
    }
    return visitor;
  }
}

export default new InvitationStore(new InvitationState(), new InvitationMutations());
