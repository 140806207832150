





















import { Component, Vue } from "vue-property-decorator";
import i18n from "@/plugins/i18n"

@Component
export default class Complete extends Vue {
  get text(){
    return i18n.html("module-visitor.view.complete.text")
  }
  get contact(){
    return i18n.html("module-visitor.view.complete.contact")
  }
  get link(){
    return i18n.html("module-visitor.view.complete.mapHyperlink")
  }
}
