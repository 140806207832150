import { render, staticRenderFns } from "./Notifier.vue?vue&type=template&id=2d1c0a12&scoped=true&"
import script from "./Notifier.vue?vue&type=script&lang=ts&"
export * from "./Notifier.vue?vue&type=script&lang=ts&"
import style0 from "./Notifier.vue?vue&type=style&index=0&id=2d1c0a12&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d1c0a12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VBadge } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {
  VAlert,
  VBadge,
  VChip,
  VFlex,
  VLayout,
})
