





















import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";

@Component
export default class NumberInput extends Vue {
  @Prop()
  readonly value!: number;
  @Prop()
  readonly min!: number | null;
  @Prop({default: 999999999999999})
  readonly max!: number | null;
  @Prop()
  readonly label!: string | null;

  get mask() {
    var val = this.max!;
    var mask = ""
    while(val > 9){
      val = val/10
      mask+="#"
    }
    return mask;
  }
  increase() {
    if (this.max == null || this.value < this.max) {
      this.$emit("input", this.value + 1);
    }
  }
  decrease() {
    if (this.min == null || this.value > this.min) {
      this.$emit("input", this.value - 1);
    }
  }
  get numericValue() {
    return (parseInt(this.value.toString()) || "").toString();
  }

  set numericValue(value: string) {
    if(value == ""){
      return 
    }
    var intVal = parseInt(value) || 0;
    console.log(intVal, this.min)
    if(this.min !== null && this.min > intVal){
      this.$emit("input", this.min);
    }
    else if(this.max !== null && this.max < intVal){
      this.$emit("input", this.max);
    }
    else{
      this.$emit("input", intVal);
    }
  }
  onFocus(event: any){
    event.target.select()
  }
}
