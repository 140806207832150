
































































































import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator";
import SearchVisitor from "./AddVisitorDialog_SearchVisitor.vue";
import VisitorDetail from "./AddVisitorDialog_VisitorDetail.vue";
import CompanyDetail from "./AddVisitorDialog_CompanyDetail.vue";
import AddCompanyForm from "./AddVisitorDialog_AddCompanyForm.vue";

import api, {
  VisitorDetailDto,
  VisitTypeVisitConditionDTO,
  VisitTypeVisitConditionDTOVisitConditionDTO,
  VisitorCreate,
  IF6040Company
} from "@/api";

import store, {
  Visitor,
  VisitType,
  VisitTypeCondition,
  VisitorStatus
} from "@/module-admin/stores/invitation";
import notifier from "@/stores/notifier";
import formRules from "@/helpers/formRules";

@Component({
  components: {
    SearchVisitor,
    VisitorDetail,
    CompanyDetail,
    AddCompanyForm
  }
})
export default class AddVisitorForm extends Vue {
  @Prop()
  readonly value!: boolean;

  get visible() {
    return this.value;
  }
  set visible(value: boolean) {
    this.$emit("input", value);
  }
  visitor: VisitorDetailDto | null = null;
  visitorSearch = "";
  company: IF6040Company | null = null;
  companySearch = "";
  selectedVisitType: VisitType | null = null;
  selectedConditions: string[] = [];
  companyDialog = false;
  createVisitorProgress = false;

  get showSearchVisitor() {
    return this.visitor == null;
  }

  @Watch("visitor")
  watchVisitor(visitor: VisitorDetailDto | null = null) {
    if (visitor && visitor.companyId) {
      this.company = {
        id: visitor.companyId,
        name: visitor.companyName,
        street: visitor.companyStreet,
        zip: visitor.companyZip,
        city: visitor.companyCity,
        country: visitor.companyCountry
      };
      this.companySearch = visitor.companyName || "";
    }
  }
  get visitTypes() {
    return store.state.visitTypes;
  }
  get conditions(): VisitTypeCondition[] {
    if (this.selectedVisitType && this.selectedVisitType.conditions) {
      return this.selectedVisitType.conditions;
    }
    return [];
  }
  get createVisitorEnabled() {
    var search = this.visitorSearch.toLowerCase();
    return search.length > 0 && formRules.email(search) == true;
  }
  createVisitor() {
    this.visitor = {
      email: this.visitorSearch
    };
  }
  onVisitorChange() {
    if (this.visitor) {
      this.visitorSearch = this.visitor.email || "";
    }
    this.visitor = null;
  }
  get isCompanyEditMode() {
    return !(this.visitor && this.visitor.id);
  }
  onCreteCompany() {
    this.companyDialog = true;
  }
  onCompanyCreated(company: IF6040Company) {
    this.company = company;
    this.companySearch = company.name || "";
    this.companyDialog = false;
  }

  get isFormValid() {
    return (
      this.visitor != null &&
      formRules.email(this.visitor.email || "") == true &&
      this.selectedVisitType &&
      this.company != null
    );
  }
  async submit() {
    if (this.visitor == null) throw "Missing visitor";
    if (this.company == null) throw "Missing company";
    if (this.selectedVisitType == null) throw "Missing visitType";
    this.createVisitorProgress = true;
    try {
      if (!this.visitor.id || this.visitor.companyId != this.company.id) {
        var newVisitorId = await this.createNewVisitor();
        if(!newVisitorId){
         return;
        }
         this.visitor.id = newVisitorId
      }
      if (this.visitor.id) {
        const visitor: Visitor = {
          id: this.visitor.id || "",
          email: this.visitor.email || "",
          firstName: "",
          lastName: "",
          visitTypeId: this.selectedVisitType.id || "",
          conditions: this.selectedConditions,
          pin: null,
          companyName: this.company.name || "",
          status: VisitorStatus.New,
          isAdHoc: false
        };
        if (store.addVisitor(visitor)) {
          this.reset();
        } else {
          notifier.addError("module-admin.invitation.msg.visitorAlreadyAdded");
        }
      }
    } finally {
      this.createVisitorProgress = false;
    }
  }

  async createNewVisitor(): Promise<string | undefined> {
    const email = this.visitorSearch;
    if (this.company && this.visitor) {
      const question = this.$t(
        "module-admin.invitation.doYouWantToCreateNewVisitorWith_email_for_company_",
        { email: email, company: this.company.name }
      ).toString();
      if (await this.$confirm(question)) {
        try {
          var visitor: VisitorCreate = {
            email: email,
            companyId: this.company.id || "",
            firstName: "",
            lastName: "",
            isAdHoc: false
          };
          const response = await api.createVisitor({ visitor });
          return response.visitorId;
        } catch (e) {
          console.error(e);
          notifier.addError(
            "module-admin.invitation.msg.visitorCanNotBeCreated"
          );
        }
      }
    }
    return undefined;
  }
  cancel() {
    this.reset();
  }
  private reset() {
    this.visible = false;
    this.selectedVisitType = null;
    this.selectedConditions = [];
    this.visitor = null;
    this.visitorSearch = "";
    this.company = null;
    this.companySearch = "";
  }
}
