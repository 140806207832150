export interface IConfig {
  apiUrl: string;
  name: string;
  locale: string;
  fallbackLocale: string;
  availableLocales: string[];
  timezone: string;
  moduleAdmin: boolean;
  moduleVisitor: boolean;
  messageTimeouts: MessageTimeouts;
  features: Features
}

class MessageTimeouts {
  info: number | null = null
  error: number | null = null
  success: number | null = null
  warning: number | null = null
}
class Features {
  hostCardEnabled: boolean = false
}

/* Application configuration binding public/config.js file */
let settings = (<any>window).appConfig as IConfig;
settings.messageTimeouts = settings.messageTimeouts || new MessageTimeouts()
settings.features = settings.features || new Features()

export default settings || {}