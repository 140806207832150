























import { Component, Vue, Emit } from "vue-property-decorator";

import InvitationForm from "../templates/InvitationForm.vue";

import invitationStore from "../stores/invitation";
import notifier from "@/stores/notifier";

@Component({
  components: {
    InvitationForm
  }
})
export default class InvitationCreateView extends Vue {
  loading = false;
  get isValid() {
    return invitationStore.isValid;
  }
  async mounted() {
    invitationStore.LoadVisitTypes();
  }
  cancel() {
    invitationStore.reset();
    this.$router.push({ name: "home" });
  }
  async submit() {
    this.loading = true;
    try {
      await invitationStore.createInvitation();
      notifier.addSuccess("module-admin.invitation.msg.invitationWasCreated");
      this.$router.push({ name: "home" });
    } catch (e) {
      notifier.addError(
        "module-admin.invitation.msg.invitationCanNotBeCreated"
      );
    }
    this.loading = false;
  }
}
