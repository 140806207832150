import Vue from "vue";
import Router from "vue-router";

import config from "@/config";

import Error404 from "@/views/Error404View.vue";
import AdminModule from "@/module-admin/AdminModule.vue";
import AdminRoutes from "@/module-admin/AdminRoutes";
import VisitorModule from "@/module-visitor/VisitorModule.vue";
import VisitorRoutes from "@/module-visitor/VisitorRoutes";

Vue.use(Router);

export default new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: "/visitor"
    },
    ...(config.moduleAdmin
      ? [
          {
            path: "/invitation",
            component: AdminModule,
            children: AdminRoutes
          }
        ]
      : []),
    ...(config.moduleVisitor
      ? [
          {
            path: "/visitor",
            component: VisitorModule,
            children: VisitorRoutes
          }
        ]
      : []),
    {
      path: "*",
      name: "not-found",
      component: Error404
    }
  ]
});
