/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as Parameters from "./models/parameters";
import { APIContext } from "./aPIContext";

class API extends APIContext {
  /**
   * Initializes a new instance of the API class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.APIOptions) {
    super(credentials, options);
  }

  /**
   * @summary Login
   * @param [options] The optional parameters
   * @returns Promise<Models.AuthenticateResponse>
   */
  authenticate(options?: Models.APIAuthenticateOptionalParams): Promise<Models.AuthenticateResponse>;
  /**
   * @param callback The callback
   */
  authenticate(callback: msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  authenticate(options: Models.APIAuthenticateOptionalParams, callback: msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>): void;
  authenticate(options?: Models.APIAuthenticateOptionalParams | msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>, callback?: msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>): Promise<Models.AuthenticateResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      authenticateOperationSpec,
      callback) as Promise<Models.AuthenticateResponse>;
  }

  /**
   * @summary Login
   * @param [options] The optional parameters
   * @returns Promise<Models.AuthenticateVisitorResponse>
   */
  authenticateVisitor(options?: Models.APIAuthenticateVisitorOptionalParams): Promise<Models.AuthenticateVisitorResponse>;
  /**
   * @param callback The callback
   */
  authenticateVisitor(callback: msRest.ServiceCallback<Models.VisitorAuthenticationResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  authenticateVisitor(options: Models.APIAuthenticateVisitorOptionalParams, callback: msRest.ServiceCallback<Models.VisitorAuthenticationResponse>): void;
  authenticateVisitor(options?: Models.APIAuthenticateVisitorOptionalParams | msRest.ServiceCallback<Models.VisitorAuthenticationResponse>, callback?: msRest.ServiceCallback<Models.VisitorAuthenticationResponse>): Promise<Models.AuthenticateVisitorResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      authenticateVisitorOperationSpec,
      callback) as Promise<Models.AuthenticateVisitorResponse>;
  }

  /**
   * @summary Refresh token
   * @param [options] The optional parameters
   * @returns Promise<Models.RefreshTokenResponse>
   */
  refreshToken(options?: msRest.RequestOptionsBase): Promise<Models.RefreshTokenResponse>;
  /**
   * @param callback The callback
   */
  refreshToken(callback: msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshToken(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>): void;
  refreshToken(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>, callback?: msRest.ServiceCallback<Models.AuthenticationControllerAuthenticationResponse>): Promise<Models.RefreshTokenResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshTokenOperationSpec,
      callback) as Promise<Models.RefreshTokenResponse>;
  }

  /**
   * @summary List all companies / mock
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCompaniesResponse>
   */
  getCompanies(options?: Models.APIGetCompaniesOptionalParams): Promise<Models.GetCompaniesResponse>;
  /**
   * @param callback The callback
   */
  getCompanies(callback: msRest.ServiceCallback<Models.IF6040Company[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCompanies(options: Models.APIGetCompaniesOptionalParams, callback: msRest.ServiceCallback<Models.IF6040Company[]>): void;
  getCompanies(options?: Models.APIGetCompaniesOptionalParams | msRest.ServiceCallback<Models.IF6040Company[]>, callback?: msRest.ServiceCallback<Models.IF6040Company[]>): Promise<Models.GetCompaniesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCompaniesOperationSpec,
      callback) as Promise<Models.GetCompaniesResponse>;
  }

  /**
   * @summary Create Company / mock
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateCompanyResponse>
   */
  createCompany(options?: Models.APICreateCompanyOptionalParams): Promise<Models.CreateCompanyResponse>;
  /**
   * @param callback The callback
   */
  createCompany(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createCompany(options: Models.APICreateCompanyOptionalParams, callback: msRest.ServiceCallback<string>): void;
  createCompany(options?: Models.APICreateCompanyOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.CreateCompanyResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createCompanyOperationSpec,
      callback) as Promise<Models.CreateCompanyResponse>;
  }

  /**
   * @summary List all available invitations
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInvitationsResponse>
   */
  getInvitations(options?: msRest.RequestOptionsBase): Promise<Models.GetInvitationsResponse>;
  /**
   * @param callback The callback
   */
  getInvitations(callback: msRest.ServiceCallback<Models.InvitationOverviewDTO[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInvitations(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.InvitationOverviewDTO[]>): void;
  getInvitations(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.InvitationOverviewDTO[]>, callback?: msRest.ServiceCallback<Models.InvitationOverviewDTO[]>): Promise<Models.GetInvitationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInvitationsOperationSpec,
      callback) as Promise<Models.GetInvitationsResponse>;
  }

  /**
   * @summary Invitation detail
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInvitationResponse>
   */
  getInvitation(options?: Models.APIGetInvitationOptionalParams): Promise<Models.GetInvitationResponse>;
  /**
   * @param callback The callback
   */
  getInvitation(callback: msRest.ServiceCallback<Models.InvitationDetail>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInvitation(options: Models.APIGetInvitationOptionalParams, callback: msRest.ServiceCallback<Models.InvitationDetail>): void;
  getInvitation(options?: Models.APIGetInvitationOptionalParams | msRest.ServiceCallback<Models.InvitationDetail>, callback?: msRest.ServiceCallback<Models.InvitationDetail>): Promise<Models.GetInvitationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInvitationOperationSpec,
      callback) as Promise<Models.GetInvitationResponse>;
  }

  /**
   * @summary List of available hosts / mock
   * @param [options] The optional parameters
   * @returns Promise<Models.GetHostsResponse>
   */
  getHosts(options?: Models.APIGetHostsOptionalParams): Promise<Models.GetHostsResponse>;
  /**
   * @param callback The callback
   */
  getHosts(callback: msRest.ServiceCallback<Models.IF6040Host[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getHosts(options: Models.APIGetHostsOptionalParams, callback: msRest.ServiceCallback<Models.IF6040Host[]>): void;
  getHosts(options?: Models.APIGetHostsOptionalParams | msRest.ServiceCallback<Models.IF6040Host[]>, callback?: msRest.ServiceCallback<Models.IF6040Host[]>): Promise<Models.GetHostsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getHostsOperationSpec,
      callback) as Promise<Models.GetHostsResponse>;
  }

  /**
   * @summary Creates invitation
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateInvitationResponse>
   */
  createInvitation(options?: Models.APICreateInvitationOptionalParams): Promise<Models.CreateInvitationResponse>;
  /**
   * @param callback The callback
   */
  createInvitation(callback: msRest.ServiceCallback<Models.InvitationCreateResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createInvitation(options: Models.APICreateInvitationOptionalParams, callback: msRest.ServiceCallback<Models.InvitationCreateResponse>): void;
  createInvitation(options?: Models.APICreateInvitationOptionalParams | msRest.ServiceCallback<Models.InvitationCreateResponse>, callback?: msRest.ServiceCallback<Models.InvitationCreateResponse>): Promise<Models.CreateInvitationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createInvitationOperationSpec,
      callback) as Promise<Models.CreateInvitationResponse>;
  }

  /**
   * @summary Update Invitation
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateInvitationResponse>
   */
  updateInvitation(options?: Models.APIUpdateInvitationOptionalParams): Promise<Models.UpdateInvitationResponse>;
  /**
   * @param callback The callback
   */
  updateInvitation(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateInvitation(options: Models.APIUpdateInvitationOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  updateInvitation(options?: Models.APIUpdateInvitationOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.UpdateInvitationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateInvitationOperationSpec,
      callback) as Promise<Models.UpdateInvitationResponse>;
  }

  /**
   * @summary Delete Invitation
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteInvitationResponse>
   */
  deleteInvitation(options?: Models.APIDeleteInvitationOptionalParams): Promise<Models.DeleteInvitationResponse>;
  /**
   * @param callback The callback
   */
  deleteInvitation(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteInvitation(options: Models.APIDeleteInvitationOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  deleteInvitation(options?: Models.APIDeleteInvitationOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.DeleteInvitationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteInvitationOperationSpec,
      callback) as Promise<Models.DeleteInvitationResponse>;
  }

  /**
   * @summary List all pre-registrations for given user / mock
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPreRegistrationsResponse>
   */
  getPreRegistrations(options?: msRest.RequestOptionsBase): Promise<Models.GetPreRegistrationsResponse>;
  /**
   * @param callback The callback
   */
  getPreRegistrations(callback: msRest.ServiceCallback<Models.PreRegistrationOverviewDTO[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPreRegistrations(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PreRegistrationOverviewDTO[]>): void;
  getPreRegistrations(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PreRegistrationOverviewDTO[]>, callback?: msRest.ServiceCallback<Models.PreRegistrationOverviewDTO[]>): Promise<Models.GetPreRegistrationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPreRegistrationsOperationSpec,
      callback) as Promise<Models.GetPreRegistrationsResponse>;
  }

  /**
   * @summary List of question and possible answers for questionnaire,, if empty no questionnaire
   * needed
   * @param [options] The optional parameters
   * @returns Promise<Models.GetQuestionnaireResponse>
   */
  getQuestionnaire(options?: Models.APIGetQuestionnaireOptionalParams): Promise<Models.GetQuestionnaireResponse>;
  /**
   * @param callback The callback
   */
  getQuestionnaire(callback: msRest.ServiceCallback<Models.QuestionnaireResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getQuestionnaire(options: Models.APIGetQuestionnaireOptionalParams, callback: msRest.ServiceCallback<Models.QuestionnaireResponse>): void;
  getQuestionnaire(options?: Models.APIGetQuestionnaireOptionalParams | msRest.ServiceCallback<Models.QuestionnaireResponse>, callback?: msRest.ServiceCallback<Models.QuestionnaireResponse>): Promise<Models.GetQuestionnaireResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getQuestionnaireOperationSpec,
      callback) as Promise<Models.GetQuestionnaireResponse>;
  }

  /**
   * @summary List of instructions needed for requested visit conditions, if empty no instructions
   * needed
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInstructionsResponse>
   */
  getInstructions(options?: Models.APIGetInstructionsOptionalParams): Promise<Models.GetInstructionsResponse>;
  /**
   * @param callback The callback
   */
  getInstructions(callback: msRest.ServiceCallback<Models.InstructionsResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInstructions(options: Models.APIGetInstructionsOptionalParams, callback: msRest.ServiceCallback<Models.InstructionsResponse>): void;
  getInstructions(options?: Models.APIGetInstructionsOptionalParams | msRest.ServiceCallback<Models.InstructionsResponse>, callback?: msRest.ServiceCallback<Models.InstructionsResponse>): Promise<Models.GetInstructionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInstructionsOperationSpec,
      callback) as Promise<Models.GetInstructionsResponse>;
  }

  /**
   * @summary Question evaluation, returns true in case of successful answer
   * @param [options] The optional parameters
   * @returns Promise<Models.EvaluateQuestionMethodResponse>
   */
  evaluateQuestionMethod(options?: Models.APIEvaluateQuestionMethodOptionalParams): Promise<Models.EvaluateQuestionMethodResponse>;
  /**
   * @param callback The callback
   */
  evaluateQuestionMethod(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  evaluateQuestionMethod(options: Models.APIEvaluateQuestionMethodOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  evaluateQuestionMethod(options?: Models.APIEvaluateQuestionMethodOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.EvaluateQuestionMethodResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      evaluateQuestionMethodOperationSpec,
      callback) as Promise<Models.EvaluateQuestionMethodResponse>;
  }

  /**
   * @summary Evaluate condition, returns true for successful answers, condition fulfilled
   * @param [options] The optional parameters
   * @returns Promise<Models.EvaluateConditionMethodResponse>
   */
  evaluateConditionMethod(options?: Models.APIEvaluateConditionMethodOptionalParams): Promise<Models.EvaluateConditionMethodResponse>;
  /**
   * @param callback The callback
   */
  evaluateConditionMethod(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  evaluateConditionMethod(options: Models.APIEvaluateConditionMethodOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  evaluateConditionMethod(options?: Models.APIEvaluateConditionMethodOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.EvaluateConditionMethodResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      evaluateConditionMethodOperationSpec,
      callback) as Promise<Models.EvaluateConditionMethodResponse>;
  }

  /**
   * @summary Evaluate requested conditions for invited visitor, returns true if visitor has all
   * required conditions done and was successfully registered for a visit
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateRequestedConditionsResponse>
   */
  validateRequestedConditions(options?: Models.APIValidateRequestedConditionsOptionalParams): Promise<Models.ValidateRequestedConditionsResponse>;
  /**
   * @param callback The callback
   */
  validateRequestedConditions(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  validateRequestedConditions(options: Models.APIValidateRequestedConditionsOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  validateRequestedConditions(options?: Models.APIValidateRequestedConditionsOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.ValidateRequestedConditionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      validateRequestedConditionsOperationSpec,
      callback) as Promise<Models.ValidateRequestedConditionsResponse>;
  }

  /**
   * @summary Returns available free visitor card
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAvailableVisitorCardResponse>
   */
  getAvailableVisitorCard(options?: msRest.RequestOptionsBase): Promise<Models.GetAvailableVisitorCardResponse>;
  /**
   * @param callback The callback
   */
  getAvailableVisitorCard(callback: msRest.ServiceCallback<Models.RegistrationControllerGetAvailableVisitorCardResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAvailableVisitorCard(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.RegistrationControllerGetAvailableVisitorCardResponse>): void;
  getAvailableVisitorCard(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.RegistrationControllerGetAvailableVisitorCardResponse>, callback?: msRest.ServiceCallback<Models.RegistrationControllerGetAvailableVisitorCardResponse>): Promise<Models.GetAvailableVisitorCardResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAvailableVisitorCardOperationSpec,
      callback) as Promise<Models.GetAvailableVisitorCardResponse>;
  }

  /**
   * @summary Returns true if registration successful
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterVisitorResponse>
   */
  registerVisitor(options?: Models.APIRegisterVisitorOptionalParams): Promise<Models.RegisterVisitorResponse>;
  /**
   * @param callback The callback
   */
  registerVisitor(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  registerVisitor(options: Models.APIRegisterVisitorOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  registerVisitor(options?: Models.APIRegisterVisitorOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.RegisterVisitorResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      registerVisitorOperationSpec,
      callback) as Promise<Models.RegisterVisitorResponse>;
  }

  /**
   * @summary Returns true if un-registration successful
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterVisitorResponse>
   */
  unregisterVisitor(options?: Models.APIUnregisterVisitorOptionalParams): Promise<Models.UnregisterVisitorResponse>;
  /**
   * @param callback The callback
   */
  unregisterVisitor(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterVisitor(options: Models.APIUnregisterVisitorOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  unregisterVisitor(options?: Models.APIUnregisterVisitorOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.UnregisterVisitorResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      unregisterVisitorOperationSpec,
      callback) as Promise<Models.UnregisterVisitorResponse>;
  }

  /**
   * @summary List of available visitors based on email
   * @param [options] The optional parameters
   * @returns Promise<Models.GetVisitorsResponse>
   */
  getVisitors(options?: Models.APIGetVisitorsOptionalParams): Promise<Models.GetVisitorsResponse>;
  /**
   * @param callback The callback
   */
  getVisitors(callback: msRest.ServiceCallback<Models.VisitorDetailDto[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getVisitors(options: Models.APIGetVisitorsOptionalParams, callback: msRest.ServiceCallback<Models.VisitorDetailDto[]>): void;
  getVisitors(options?: Models.APIGetVisitorsOptionalParams | msRest.ServiceCallback<Models.VisitorDetailDto[]>, callback?: msRest.ServiceCallback<Models.VisitorDetailDto[]>): Promise<Models.GetVisitorsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getVisitorsOperationSpec,
      callback) as Promise<Models.GetVisitorsResponse>;
  }

  /**
   * @summary Returns visitor detail for provided id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetVisitorResponse>
   */
  getVisitor(options?: Models.APIGetVisitorOptionalParams): Promise<Models.GetVisitorResponse>;
  /**
   * @param callback The callback
   */
  getVisitor(callback: msRest.ServiceCallback<Models.VisitorDetailDto>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getVisitor(options: Models.APIGetVisitorOptionalParams, callback: msRest.ServiceCallback<Models.VisitorDetailDto>): void;
  getVisitor(options?: Models.APIGetVisitorOptionalParams | msRest.ServiceCallback<Models.VisitorDetailDto>, callback?: msRest.ServiceCallback<Models.VisitorDetailDto>): Promise<Models.GetVisitorResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getVisitorOperationSpec,
      callback) as Promise<Models.GetVisitorResponse>;
  }

  /**
   * @summary Create visitor
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateVisitorResponse>
   */
  createVisitor(options?: Models.APICreateVisitorOptionalParams): Promise<Models.CreateVisitorResponse>;
  /**
   * @param callback The callback
   */
  createVisitor(callback: msRest.ServiceCallback<Models.VisitorCreateResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createVisitor(options: Models.APICreateVisitorOptionalParams, callback: msRest.ServiceCallback<Models.VisitorCreateResponse>): void;
  createVisitor(options?: Models.APICreateVisitorOptionalParams | msRest.ServiceCallback<Models.VisitorCreateResponse>, callback?: msRest.ServiceCallback<Models.VisitorCreateResponse>): Promise<Models.CreateVisitorResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createVisitorOperationSpec,
      callback) as Promise<Models.CreateVisitorResponse>;
  }

  /**
   * @summary Update visitor
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateVisitorResponse>
   */
  updateVisitor(options?: Models.APIUpdateVisitorOptionalParams): Promise<Models.UpdateVisitorResponse>;
  /**
   * @param callback The callback
   */
  updateVisitor(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateVisitor(options: Models.APIUpdateVisitorOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateVisitor(options?: Models.APIUpdateVisitorOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateVisitorResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateVisitorOperationSpec,
      callback) as Promise<Models.UpdateVisitorResponse>;
  }

  /**
   * @summary List of available types and conditions
   * @param [options] The optional parameters
   * @returns Promise<Models.GetConditionsResponse>
   */
  getConditions(options?: msRest.RequestOptionsBase): Promise<Models.GetConditionsResponse>;
  /**
   * @param callback The callback
   */
  getConditions(callback: msRest.ServiceCallback<Models.VisitTypeVisitConditionDTO[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getConditions(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.VisitTypeVisitConditionDTO[]>): void;
  getConditions(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.VisitTypeVisitConditionDTO[]>, callback?: msRest.ServiceCallback<Models.VisitTypeVisitConditionDTO[]>): Promise<Models.GetConditionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getConditionsOperationSpec,
      callback) as Promise<Models.GetConditionsResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const authenticateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Authentication/Authenticate",
  requestBody: {
    parameterPath: [
      "options",
      "authenticationData"
    ],
    mapper: Mappers.AuthenticationDto
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.AuthenticationControllerAuthenticationResponse
    },
    default: {}
  },
  serializer
};

const authenticateVisitorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Authentication/AuthenticateVisitor",
  requestBody: {
    parameterPath: [
      "options",
      "authenticationData"
    ],
    mapper: Mappers.VisitorAuthenticationDto
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.VisitorAuthenticationResponse
    },
    default: {}
  },
  serializer
};

const refreshTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Authentication/RefreshToken",
  responses: {
    200: {
      bodyMapper: Mappers.AuthenticationControllerAuthenticationResponse
    },
    default: {}
  },
  serializer
};

const getCompaniesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "companies/GetCompanies",
  queryParameters: [
    Parameters.inputText
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IF6040Company"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createCompanyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "companies/CreateCompany",
  requestBody: {
    parameterPath: [
      "options",
      "company"
    ],
    mapper: Mappers.CompanyCreate
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const getInvitationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Invitation/GetInvitations",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InvitationOverviewDTO"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getInvitationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Invitation/GetInvitation",
  queryParameters: [
    Parameters.invitationGuid
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InvitationDetail
    },
    default: {}
  },
  serializer
};

const getHostsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Invitation/GetHosts",
  queryParameters: [
    Parameters.inputText
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IF6040Host"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const createInvitationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Invitation/CreateInvitation",
  requestBody: {
    parameterPath: [
      "options",
      "invitation"
    ],
    mapper: Mappers.InvitationCreate
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.InvitationCreateResponse
    },
    default: {}
  },
  serializer
};

const updateInvitationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Invitation/UpdateInvitation",
  requestBody: {
    parameterPath: [
      "options",
      "invitation"
    ],
    mapper: Mappers.InvitationUpdate
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteInvitationOperationSpec: msRest.OperationSpec = {
  httpMethod: "DELETE",
  path: "Invitation/DeleteInvitation",
  queryParameters: [
    Parameters.invitationId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const getPreRegistrationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "PreRegistration/GetPreRegistrations",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreRegistrationOverviewDTO"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getQuestionnaireOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Questionnaire/GetQuestionnaire",
  queryParameters: [
    Parameters.invitationId,
    Parameters.visitorId,
    Parameters.languageCode
  ],
  responses: {
    200: {
      bodyMapper: Mappers.QuestionnaireResponse
    },
    default: {}
  },
  serializer
};

const getInstructionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Questionnaire/GetInstructions",
  queryParameters: [
    Parameters.invitationId,
    Parameters.visitorId,
    Parameters.languageCode
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InstructionsResponse
    },
    default: {}
  },
  serializer
};

const evaluateQuestionMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Questionnaire/EvaluateQuestion",
  requestBody: {
    parameterPath: [
      "options",
      "evaluateQuestion"
    ],
    mapper: Mappers.EvaluateQuestion
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const evaluateConditionMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Questionnaire/EvaluateCondition",
  requestBody: {
    parameterPath: [
      "options",
      "evaluateCondition"
    ],
    mapper: Mappers.EvaluateCondition
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const validateRequestedConditionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Questionnaire/ValidateRequestedConditions",
  requestBody: {
    parameterPath: [
      "options",
      "evaluateRequestedConditions"
    ],
    mapper: Mappers.EvaluateRequestedConditions
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const getAvailableVisitorCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Registration/GetAvailableVisitorCard",
  responses: {
    200: {
      bodyMapper: Mappers.RegistrationControllerGetAvailableVisitorCardResponse
    },
    default: {}
  },
  serializer
};

const registerVisitorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Registration/RegisterVisitor",
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.RegistrationControllerRegisterVisitorRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const unregisterVisitorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Registration/UnregisterVisitor",
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.RegistrationControllerUnregisterVisitorRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    default: {}
  },
  serializer
};

const getVisitorsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Visitor/GetVisitors",
  queryParameters: [
    Parameters.inputText
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VisitorDetailDto"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getVisitorOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Visitor/GetVisitor",
  queryParameters: [
    Parameters.invitationId,
    Parameters.visitorId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.VisitorDetailDto
    },
    default: {}
  },
  serializer
};

const createVisitorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Visitor/CreateVisitor",
  requestBody: {
    parameterPath: [
      "options",
      "visitor"
    ],
    mapper: Mappers.VisitorCreate
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.VisitorCreateResponse
    },
    default: {}
  },
  serializer
};

const updateVisitorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "Visitor/UpdateVisitor",
  requestBody: {
    parameterPath: [
      "options",
      "visitor"
    ],
    mapper: Mappers.VisitorUpdate
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    default: {}
  },
  serializer
};

const getConditionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "Visitor/GetConditions",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VisitTypeVisitConditionDTO"
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

export {
  API,
  APIContext,
  Models as APIModels,
  Mappers as APIMappers
};
