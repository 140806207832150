import { SyncedStore } from "pipaslot-vuex-typescript";
import api, { APIGetInstructionsOptionalParams, APIGetQuestionnaireOptionalParams, EvaluateQuestion, QuestionnaireResponseConditionQuestionAnswer as Answer } from "@/api";
import appStore, { AuthenticationSource } from "@/stores/app"
import i18n from "@/plugins/i18n"
import { QuestionGroup, Question, VisitorState, Instruction, Video, reduceQuestions } from './visitor.state';
import VisitorMutations from './visitor.mutations';

export * from './visitor.state'
export class VisitorStore extends SyncedStore<VisitorState, VisitorMutations> {
  reset() {
    this.mutations.reset()
  }
  async setPin(pin: string) {
    this.mutations.reset()
    const response = await api.authenticateVisitor({
      authenticationData: {
        pin: pin
      }
    });
    appStore.authorize(response.token || (response.visitorId || null), AuthenticationSource.Visitor);
    this.mutations.setVisitor(response.invitationId || null, response.visitorId || null)
  }
  get questions(): Question[] {
    return reduceQuestions(this.state.questionGroups)
  }
  public resetQuestions() {
    this.mutations.setQuestionGroups([])
  }

  public async loadInstructions(language:string): Promise<boolean> {
    var instructionReponse = await api.getInstructions({
      visitorId: this.state.visitorId,
      invitationId: this.state.invitationId,
      languageCode: language
    } as APIGetInstructionsOptionalParams);

    var visitTypeName = instructionReponse.visitTypeName || "unknownVisitType"
    var instructions = (instructionReponse.conditions || []).map(condition => {
      var source = condition.mediaUrl ?
        {
          type: "video/mp4",
          src: condition.mediaUrl
        }
        : null
      return {
        id: visitTypeName + "#" + condition.name,
        name: `enum.visitType.${visitTypeName}.conditions.${condition.name}`,
        sources: source ? [source] : [],
        poster: null
      } as Instruction
    });
    
    this.mutations.setInstructions(instructions)
    return instructions.length > 0
  }
  async loadQuestions(language:string): Promise<boolean> {
    if (this.state.questionGroups.length > 0) {
      return true
    }
    var questionnaire = await api.getQuestionnaire({
      visitorId: this.state.visitorId,
      invitationId: this.state.invitationId,
      languageCode: language
    } as APIGetQuestionnaireOptionalParams)

    var groups = (questionnaire.conditions || []).map(c => <QuestionGroup>{
      id: c.conditionId,
      isCompleted: false,
      questions: (c.questions || []).map(q => <Question>{
        id: q.questionId || "unknown",
        text: q.text,
        image: q.url || null,
        selectedAnswer: null,
        isAnswered: false,
        isCorrect: null,
        answers: (q.answers || []).map(a => <Answer>{
          id: a.id,
          text: a.text
        })
      })
        .filter(q => q.answers.length > 0)
    })
      .filter(g => g.questions.length > 0)

    if (groups.length > 0) {
      this.mutations.setQuestionGroups(groups)
      return true
    }
    return false
  }
  async evaluateQuestion(questionId: string, answerId: string) {
    var response = await api.evaluateQuestionMethod({
      evaluateQuestion: {
        questionId: questionId,
        answerId: answerId
      }
    })
    this.mutations.setIsCorrectAnswer(questionId, response.body)
    await this.checkGroupCompletion(questionId)
  }

  async evaluateQuestionnaire(): Promise<boolean> {
    var response = await api.validateRequestedConditions({
      evaluateRequestedConditions: {
        visitorId: this.state.visitorId!,
        invitationId: this.state.invitationId!
      }
    })
    return response.body
  }
  private async checkGroupCompletion(questionId: string) {
    var group = this.state.questionGroups.find(g => g.questions.some(q => q.id == questionId))
    if (group && group.questions.every(q => q.isCorrect === true)) {
      var response = await api.evaluateConditionMethod({
        evaluateCondition: {
          visitorId: this.state.visitorId!,
          conditionId: group.id!,
          questionsWithAnswers: group.questions.map(q => <EvaluateQuestion>{
            questionId: q.id,
            answerId: q.selectedAnswer
          })
        }
      })
      this.mutations.setGroupCompleted(group.id, response.body)
    }
  }
  public resetWrongAnswers(){
    this.mutations.resetWrongAnswers()
  }
}

export default new VisitorStore(new VisitorState(), new VisitorMutations());
