import Vue from "vue"
import { NavigationGuard } from 'vue-router';
import multiguard from 'vue-router-multiguard';

import VisitorForm from "./views/VisitorForm.vue"
import Instructions from "./views/Instructions.vue"
import About from "./views/About.vue"
import Pin from "./views/PinView.vue"
import Questionnaire from "./views/Questionnaire.vue"
import Complete from "./views/Complete.vue"
import DataProtection from "./views/DataProtection.vue"

import appStore, { AuthenticationSource } from "@/stores/app";
import visitor from "@/module-visitor/stores/visitor"

let authenticationGuard: NavigationGuard<Vue> = (to, from, next) => {
  let isAuthenticated = appStore.isAuthenticated &&
    appStore.state.authorizationSource == AuthenticationSource.Visitor
  if (isAuthenticated) {
    next()
  } else {
    next({ path: "/visitor/login" })
  }
}
export default [
  {
    path: "",
    redirect: "login"
  },
  {
    path: "login",
    component: Pin,
    meta: { logo: false }
  },
  {
    path: "about",
    component: About,
    beforeEnter: authenticationGuard,
    meta: { step: 1, logo: true  }
  },
  {
    path: "data-protection",
    component: DataProtection,
    beforeEnter: authenticationGuard,
    meta: { step: 2, logo: true  }
  },
  {
    path: "form",
    component: VisitorForm,
    beforeEnter: authenticationGuard,
    meta: { step: 3, logo: true  }
  },
  {
    path: "instructions",
    component: Instructions,
    beforeEnter: authenticationGuard,
    meta: { step: 4, logo: true  }
  },
  {
    path: "questionnaire",
    component: Questionnaire,
    beforeEnter: authenticationGuard,
    meta: { step: 5, logo: true }
  },
  {
    path: "complete",
    component: Complete,
    meta: { logo: true }
  },
  {
    path: "*",
    redirect: "login"
  }

];
