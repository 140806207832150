












































import { Component, Vue } from "vue-property-decorator";

import Notifier from "@/components/Notifier.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";

import config from "@/config";

import rootStore from "@/plugins/store";

@Component({
  components: {
    Notifier,
    LanguageSelect
  }
})
export default class App extends Vue {
  data() {
    return {};
  }
  get appName() {
    return config.name;
  }
  get userName() {
    return "";
  }
  logout() {
    rootStore.clear();
  }

  mounted() {
    document.addEventListener('scroll', (event) => {
      const el = document.getElementById('navigation');
      if (el && el !== undefined) {
        if (window.pageYOffset > 104) {
          el.classList.add('nav-toolbar--fixed');
        } else {
          el.classList.remove('nav-toolbar--fixed');
        }
      }
    })
  }

  
}
