



























import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";
import api, { IF6040Host } from "@/api";
import formRules from "@/helpers/formRules";

@Component
export default class SearchHost extends Vue {
  @Prop()
  readonly value!: IF6040Host;

  get model() {
    return this.value;
  }
  set model(value: IF6040Host|null) {
    this.$emit("input", value);
  }
  get items() {
    if (this.hosts.length == 0 && this.value) {
      return [this.value];
    }
    return this.hosts;
  }

  get rulesAutocomplete() {
    return [formRules.required];
  }
  hosts: IF6040Host[] = [];
  search: string = "";

  @Watch("search")
  async onSearch(value: string) {
    if(this.model && this.joinName(this.model)!= value){
      this.model = null
      this.search = ""
    }
    else if (value && value.length > 0) {
      this.hosts = await api.getHosts({
        inputText: value
      });
    }
  }
  joinName(host: IF6040Host){
    return host.firstname + " "+ host.lastname
  }
}
