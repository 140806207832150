
<template>
  <div class="spinner">
    <div class="spinner-overlay"></div>
    <div class="spinner-wrapper">
      <v-progress-circular indeterminate color="primary" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped >
@media screen {
  .spinner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1000;
  }
  .spinner-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }
  .spinner-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1001;
    display: block;
    height: 40px;
    width: 40px;
  }
}
</style>
