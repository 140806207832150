












import { Component, Vue, Prop } from "vue-property-decorator";
import i18n from "@/plugins/i18n";

let content = require("@/locales/country/default.json");
let countryCodes = Object.keys(content);

interface CountryItem {
  code: string;
  name: string;
}

@Component
export default class Country extends Vue {
  @Prop()
  readonly value!: string;
  @Prop()
  readonly label!: string;

  get _label() {
    return this.label ? this.label : i18n.t("general.country");
  }
  country: CountryItem | null = null;
  get countries(): CountryItem[] {
    let collection: CountryItem[] = [];
    countryCodes.forEach(code => {
      var translation = i18n.t("country." + code).toString();
      collection.push({
        code: code,
        name: translation
      });
    });
    return collection;
  }

  onChange() {
    this.$emit("input", this.country ? this.country.code : null);
  }
}
