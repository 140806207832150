import 'babel-polyfill';
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from "vue";
import "@/plugins/vuetify";
import App from "@/Application.vue";
import router from "@/plugins/router";
import i18n from "@/plugins/i18n";
import rootStore from "@/plugins/store";
import "@/plugins/confirm";
import "@/assets/style.scss";
import config from "@/config";
import * as moment from "moment-timezone"

import {httpClient} from '@/api';
import { EVENT_LOGOUT } from '@/api/HttpClient';

import Country from "@/components/Country.vue";
import DateInput from "@/components/DateInput.vue";
import DateTime from "@/components/DateTime.vue";
import NumberInput from "@/components/NumberInput.vue";
import Pdf from "@/components/Pdf.vue";
import Spinner from "@/components/Spinner.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import {events as appEvents,EVENT_AUTHENTICATION_EXPIRATION} from "@/stores/app";

moment.tz.setDefault(config.timezone)
Vue.config.productionTip = false;

Vue.component("Country", Country);
Vue.component("DateInput", DateInput);
Vue.component("DateTime", DateTime);
Vue.component("NumberInput", NumberInput);
Vue.component("Spinner", Spinner);
Vue.component("Pdf", Pdf);
Vue.component("VideoPlayer", VideoPlayer);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");

//Setup Application name in Html title
window.document.title = config.name;

httpClient.events.addListener(EVENT_LOGOUT,function(){
  if(rootStore.app.isAuthenticated){
    rootStore.clear()
    console.log("logout from api")
    // Reload page to fix routing reflecting current state
    window.location.reload()
  }
});
appEvents.addListener(EVENT_AUTHENTICATION_EXPIRATION, function(){
  console.log("token expired")
  window.location.reload()
})