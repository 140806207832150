/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const inputText: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "inputText"
  ],
  mapper: {
    serializedName: "inputText",
    type: {
      name: "String"
    }
  }
};
export const invitationGuid: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "invitationGuid"
  ],
  mapper: {
    serializedName: "invitationGuid",
    type: {
      name: "Uuid"
    }
  }
};
export const invitationId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "invitationId"
  ],
  mapper: {
    serializedName: "invitationId",
    type: {
      name: "Uuid"
    }
  }
};
export const languageCode: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "languageCode"
  ],
  mapper: {
    serializedName: "languageCode",
    type: {
      name: "String"
    }
  }
};
export const visitorId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "visitorId"
  ],
  mapper: {
    serializedName: "visitorId",
    type: {
      name: "Uuid"
    }
  }
};
