















import { Component, Vue } from "vue-property-decorator";
import appStore from "@/stores/app";
import config from "@/config";

@Component
export default class LanguageSelectButtons extends Vue {
  get langs() {
    return config.availableLocales;
  }
  get locale() {
    return appStore.state.locale;
  }
  getImage(locale: string) {
    return "images/flags/" + locale + ".svg";
  }
  changeLocale(locale: string) {
    appStore.setLocale(locale);
  }
}
