















































import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import config from "@/config";
import api from "@/api";
import notifier from "@/stores/notifier";
import i18n from "@/plugins/i18n";
import { VisitorStatus } from "../../stores/invitation";
import { VisitorState } from "../../../module-visitor/stores/visitor";

@Component
export default class HostCardFeature extends Vue {
  @Prop()
  readonly value?: VisitorStatus;
  @Prop()
  readonly invitationId?: string;
  @Prop()
  readonly visitorId?: string;
  isDialog = false;
  isGetNextProgress = false;
  isOkProgress = false;
  isUnregisterProgress = false;
  cardNumber: string = this.value || "";
  get isEnabled() {
    return (
      config.features.hostCardEnabled &&
      this.visitorId &&
      this.invitationId &&
      (this.value == VisitorStatus.Registered ||
        this.value == VisitorStatus.Preregistered ||
        this.value == VisitorStatus.Deregistered)
    );
  }
  get isRegistered() {
    return this.value == VisitorStatus.Registered;
  }
  async onRegisterClick() {
    this.isDialog = true;
    this.isGetNextProgress = true;
    await this.loadNextCardNumber();
    this.isGetNextProgress = false;
  }
  async onOkClick() {
    if (!this.invitationId) {
      console.error("Missing invitiation ID");
    }
    if (!this.visitorId) {
      console.error("Missing visitor ID");
    }
    if (
      this.cardNumber &&
      this.invitationId &&
      this.visitorId &&
      this.value != this.cardNumber
    ) {
      this.isOkProgress = true;
      try {
        await api.registerVisitor({
          request: {
            visitorId: this.visitorId,
            invitationId: this.invitationId,
            cardNumber: this.cardNumber
          }
        });
        this.$emit("input", VisitorStatus.Registered);
        this.isDialog = false;
      } catch (e) {
        console.error(e);
        notifier.addError(
          i18n.t("responseErrorType.operationFailed").toString()
        );
      } finally {
        this.isOkProgress = false;
      }
    }
  }
  async onUnregisterClick() {
    if (!this.invitationId || !this.visitorId) {
      console.error("Missing invitiation ID or visitor ID");
    }
    this.isUnregisterProgress = true;
    try {
      await api.unregisterVisitor({
        request: {
          visitorId: this.visitorId,
          invitationId: this.invitationId
        }
      });
        this.$emit("input", VisitorStatus.Deregistered);
    } catch (e) {
      console.error(e);
      notifier.addError(i18n.t("responseErrorType.operationFailed").toString());
    } finally {
      this.isUnregisterProgress = false;
    }
  }
  async onGetNextClick() {
    this.isGetNextProgress = true;
    await this.loadNextCardNumber();
    this.isGetNextProgress = false;
  }
  async loadNextCardNumber() {
    try {
      var response = await api.getAvailableVisitorCard();
      this.cardNumber = response.cardNumber!;
    } catch (e) {
      console.error(e);
      notifier.addError(i18n.t("responseErrorType.operationFailed").toString());
    }
  }
}
