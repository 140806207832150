
































import { Component, Vue, Prop } from "vue-property-decorator";
import api, { IF6040Company, CompanyCreate } from "@/api";
import notifier from "@/stores/notifier";
import i18n from "@/plugins/i18n";
@Component
export default class AddCompanyForm extends Vue {
  @Prop()
  name!: string;

  sending = false;
  companyName = this.name;
  street = "";
  zipCode = "";
  city = "";
  country: string | null = null;
  get isValid() {
    return (
      this.companyName.trim() &&
      this.street.trim() &&
      this.zipCode.trim() &&
      this.city.trim() &&
      this.country
    );
  }
  async submit() {
    this.sending = true;
    try {
      var companyCreate: CompanyCreate = {
        name: this.companyName,
        street: this.street,
        zipCode: this.zipCode,
        city: this.city,
        country: this.country || ""
      };
      let companyId = await api.createCompany({
        company: companyCreate
      });
      let company: IF6040Company = {
        id: companyId.body,
        name: this.companyName,
        street: this.street,
        zip: this.zipCode,
        city: this.city,
        country: this.country || ""
      };
      this.$emit("submit", company);
    }catch (e){
      console.error(e);
      notifier.addError(
          i18n.t("responseErrorType.operationFailed").toString()
        );
    } finally {
      this.sending = false;
    }
  }
  cancel() {
    this.$emit("cancel");
  }
}
