/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const AuthenticationDto: msRest.CompositeMapper = {
  serializedName: "AuthenticationDto",
  type: {
    name: "Composite",
    className: "AuthenticationDto",
    modelProperties: {
      username: {
        required: true,
        serializedName: "username",
        type: {
          name: "String"
        }
      },
      password: {
        required: true,
        serializedName: "password",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AuthenticationControllerAuthenticationResponse: msRest.CompositeMapper = {
  serializedName: "AuthenticationController_AuthenticationResponse",
  type: {
    name: "Composite",
    className: "AuthenticationControllerAuthenticationResponse",
    modelProperties: {
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const VisitorAuthenticationDto: msRest.CompositeMapper = {
  serializedName: "VisitorAuthenticationDto",
  type: {
    name: "Composite",
    className: "VisitorAuthenticationDto",
    modelProperties: {
      pin: {
        required: true,
        serializedName: "pin",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const VisitorAuthenticationResponse: msRest.CompositeMapper = {
  serializedName: "VisitorAuthenticationResponse",
  type: {
    name: "Composite",
    className: "VisitorAuthenticationResponse",
    modelProperties: {
      token: {
        serializedName: "token",
        type: {
          name: "String"
        }
      },
      visitorId: {
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      },
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const IF6040Company: msRest.CompositeMapper = {
  serializedName: "IF6040Company",
  type: {
    name: "Composite",
    className: "IF6040Company",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      zip: {
        serializedName: "zip",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CompanyCreate: msRest.CompositeMapper = {
  serializedName: "CompanyCreate",
  type: {
    name: "Composite",
    className: "CompanyCreate",
    modelProperties: {
      name: {
        required: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      street: {
        required: true,
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      zipCode: {
        required: true,
        serializedName: "zipCode",
        type: {
          name: "String"
        }
      },
      city: {
        required: true,
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      country: {
        required: true,
        serializedName: "country",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InvitationOverviewDTOHostDTO: msRest.CompositeMapper = {
  serializedName: "InvitationOverviewDTO_HostDTO",
  type: {
    name: "Composite",
    className: "InvitationOverviewDTOHostDTO",
    modelProperties: {
      firstname: {
        serializedName: "firstname",
        type: {
          name: "String"
        }
      },
      lastname: {
        serializedName: "lastname",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InvitationOverviewDTO: msRest.CompositeMapper = {
  serializedName: "InvitationOverviewDTO",
  type: {
    name: "Composite",
    className: "InvitationOverviewDTO",
    modelProperties: {
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      },
      visitFrom: {
        serializedName: "visitFrom",
        type: {
          name: "DateTime"
        }
      },
      visitTo: {
        serializedName: "visitTo",
        type: {
          name: "DateTime"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      hostName: {
        serializedName: "hostName",
        type: {
          name: "Composite",
          className: "InvitationOverviewDTOHostDTO"
        }
      },
      numberOfVisitors: {
        serializedName: "numberOfVisitors",
        type: {
          name: "Number"
        }
      },
      numberOfRegisteredVisitors: {
        serializedName: "numberOfRegisteredVisitors",
        type: {
          name: "Number"
        }
      },
      nrOfMealsInCanteen: {
        serializedName: "nrOfMealsInCanteen",
        type: {
          name: "Number"
        }
      },
      nrOfVisitorsInGroupVisit: {
        serializedName: "nrOfVisitorsInGroupVisit",
        type: {
          name: "Number"
        }
      },
      isProfessionVisit: {
        serializedName: "isProfessionVisit",
        type: {
          name: "Boolean"
        }
      },
      isPictureAllowance: {
        serializedName: "isPictureAllowance",
        type: {
          name: "Boolean"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InvitationDetailVisitConditionDTO: msRest.CompositeMapper = {
  serializedName: "InvitationDetail_VisitConditionDTO",
  type: {
    name: "Composite",
    className: "InvitationDetailVisitConditionDTO",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Uuid"
        }
      },
      isFulfilled: {
        serializedName: "isFulfilled",
        type: {
          name: "Boolean"
        }
      },
      fulfilledUntil: {
        serializedName: "fulfilledUntil",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const InvitationDetailInvitedVisitor: msRest.CompositeMapper = {
  serializedName: "InvitationDetail_InvitedVisitor",
  type: {
    name: "Composite",
    className: "InvitationDetailInvitedVisitor",
    modelProperties: {
      visitorId: {
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      },
      firstname: {
        serializedName: "firstname",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      visitType: {
        serializedName: "visitType",
        type: {
          name: "String"
        }
      },
      visitTypeId: {
        serializedName: "visitTypeId",
        type: {
          name: "Uuid"
        }
      },
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Uuid"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      pin: {
        serializedName: "pin",
        type: {
          name: "String"
        }
      },
      notificationEmailSentSuccessfully: {
        serializedName: "notificationEmailSentSuccessfully",
        type: {
          name: "Boolean"
        }
      },
      lastNotificationEmailSentOn: {
        serializedName: "lastNotificationEmailSentOn",
        type: {
          name: "DateTime"
        }
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InvitationDetailVisitConditionDTO"
            }
          }
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IF6040Host: msRest.CompositeMapper = {
  serializedName: "IF6040Host",
  type: {
    name: "Composite",
    className: "IF6040Host",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstname: {
        serializedName: "firstname",
        type: {
          name: "String"
        }
      },
      lastname: {
        serializedName: "lastname",
        type: {
          name: "String"
        }
      },
      personNumber: {
        serializedName: "personNumber",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InvitationDetail: msRest.CompositeMapper = {
  serializedName: "InvitationDetail",
  type: {
    name: "Composite",
    className: "InvitationDetail",
    modelProperties: {
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      },
      visitFrom: {
        serializedName: "visitFrom",
        type: {
          name: "DateTime"
        }
      },
      visitTo: {
        serializedName: "visitTo",
        type: {
          name: "DateTime"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      invitedVisitors: {
        serializedName: "invitedVisitors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InvitationDetailInvitedVisitor"
            }
          }
        }
      },
      hasMailNotification: {
        serializedName: "hasMailNotification",
        type: {
          name: "Boolean"
        }
      },
      host: {
        serializedName: "host",
        type: {
          name: "Composite",
          className: "IF6040Host"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      },
      isMeetingRoom: {
        serializedName: "isMeetingRoom",
        type: {
          name: "Boolean"
        }
      },
      meetingRoomNote: {
        serializedName: "meetingRoomNote",
        type: {
          name: "String"
        }
      },
      nrOfVisitorsInGroupVisit: {
        serializedName: "nrOfVisitorsInGroupVisit",
        type: {
          name: "Number"
        }
      },
      nrOfMealsInCanteen: {
        serializedName: "nrOfMealsInCanteen",
        type: {
          name: "Number"
        }
      },
      isPictureAllowance: {
        serializedName: "isPictureAllowance",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const InvitedVisitorWithConditionSelectedCondition: msRest.CompositeMapper = {
  serializedName: "InvitedVisitorWithCondition_SelectedCondition",
  type: {
    name: "Composite",
    className: "InvitedVisitorWithConditionSelectedCondition",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const InvitedVisitorWithCondition: msRest.CompositeMapper = {
  serializedName: "InvitedVisitorWithCondition",
  type: {
    name: "Composite",
    className: "InvitedVisitorWithCondition",
    modelProperties: {
      visitorId: {
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      },
      visitTypeId: {
        serializedName: "visitTypeId",
        type: {
          name: "Uuid"
        }
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InvitedVisitorWithConditionSelectedCondition"
            }
          }
        }
      }
    }
  }
};

export const InvitationCreate: msRest.CompositeMapper = {
  serializedName: "InvitationCreate",
  type: {
    name: "Composite",
    className: "InvitationCreate",
    modelProperties: {
      hostId: {
        required: true,
        serializedName: "hostId",
        type: {
          name: "Uuid"
        }
      },
      from: {
        required: true,
        serializedName: "from",
        type: {
          name: "DateTime"
        }
      },
      to: {
        required: true,
        serializedName: "to",
        type: {
          name: "DateTime"
        }
      },
      subject: {
        required: true,
        serializedName: "subject",
        constraints: {
          MaxLength: 998
        },
        type: {
          name: "String"
        }
      },
      hasMailNotification: {
        required: true,
        serializedName: "hasMailNotification",
        type: {
          name: "Boolean"
        }
      },
      isMeetingRoom: {
        serializedName: "isMeetingRoom",
        type: {
          name: "Boolean"
        }
      },
      meetingRoomNote: {
        serializedName: "meetingRoomNote",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      },
      isPictureAllowance: {
        serializedName: "isPictureAllowance",
        type: {
          name: "Boolean"
        }
      },
      nrOfVisitorsInGroupVisit: {
        serializedName: "nrOfVisitorsInGroupVisit",
        type: {
          name: "Number"
        }
      },
      nrOfMealsInCanteen: {
        serializedName: "nrOfMealsInCanteen",
        type: {
          name: "Number"
        }
      },
      invitedVisitorsWithCondition: {
        required: true,
        serializedName: "invitedVisitorsWithCondition",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InvitedVisitorWithCondition"
            }
          }
        }
      }
    }
  }
};

export const InvitationCreateResponse: msRest.CompositeMapper = {
  serializedName: "InvitationCreateResponse",
  type: {
    name: "Composite",
    className: "InvitationCreateResponse",
    modelProperties: {
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const InvitationUpdate: msRest.CompositeMapper = {
  serializedName: "InvitationUpdate",
  type: {
    name: "Composite",
    className: "InvitationUpdate",
    modelProperties: {
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      hostId: {
        required: true,
        serializedName: "hostId",
        type: {
          name: "Uuid"
        }
      },
      from: {
        required: true,
        serializedName: "from",
        type: {
          name: "DateTime"
        }
      },
      to: {
        required: true,
        serializedName: "to",
        type: {
          name: "DateTime"
        }
      },
      subject: {
        required: true,
        serializedName: "subject",
        constraints: {
          MaxLength: 998
        },
        type: {
          name: "String"
        }
      },
      hasMailNotification: {
        required: true,
        serializedName: "hasMailNotification",
        type: {
          name: "Boolean"
        }
      },
      isMeetingRoom: {
        serializedName: "isMeetingRoom",
        type: {
          name: "Boolean"
        }
      },
      meetingRoomNote: {
        serializedName: "meetingRoomNote",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      },
      isPictureAllowance: {
        serializedName: "isPictureAllowance",
        type: {
          name: "Boolean"
        }
      },
      nrOfVisitorsInGroupVisit: {
        serializedName: "nrOfVisitorsInGroupVisit",
        type: {
          name: "Number"
        }
      },
      nrOfMealsInCanteen: {
        serializedName: "nrOfMealsInCanteen",
        type: {
          name: "Number"
        }
      },
      invitedVisitorsWithCondition: {
        required: true,
        serializedName: "invitedVisitorsWithCondition",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InvitedVisitorWithCondition"
            }
          }
        }
      }
    }
  }
};

export const PreRegistrationOverviewDTOVisitorDTO: msRest.CompositeMapper = {
  serializedName: "PreRegistrationOverviewDTO_VisitorDTO",
  type: {
    name: "Composite",
    className: "PreRegistrationOverviewDTOVisitorDTO",
    modelProperties: {
      firstname: {
        serializedName: "firstname",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PreRegistrationOverviewDTO: msRest.CompositeMapper = {
  serializedName: "PreRegistrationOverviewDTO",
  type: {
    name: "Composite",
    className: "PreRegistrationOverviewDTO",
    modelProperties: {
      preregistrationId: {
        serializedName: "preregistrationId",
        type: {
          name: "Uuid"
        }
      },
      visitFrom: {
        serializedName: "visitFrom",
        type: {
          name: "DateTime"
        }
      },
      visitTo: {
        serializedName: "visitTo",
        type: {
          name: "DateTime"
        }
      },
      subject: {
        serializedName: "subject",
        type: {
          name: "String"
        }
      },
      visitors: {
        serializedName: "visitors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PreRegistrationOverviewDTOVisitorDTO"
            }
          }
        }
      }
    }
  }
};

export const QuestionnaireResponseConditionQuestionAnswer: msRest.CompositeMapper = {
  serializedName: "QuestionnaireResponse_Condition_Question_Answer",
  type: {
    name: "Composite",
    className: "QuestionnaireResponseConditionQuestionAnswer",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const QuestionnaireResponseConditionQuestion: msRest.CompositeMapper = {
  serializedName: "QuestionnaireResponse_Condition_Question",
  type: {
    name: "Composite",
    className: "QuestionnaireResponseConditionQuestion",
    modelProperties: {
      questionId: {
        serializedName: "questionId",
        type: {
          name: "Uuid"
        }
      },
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      answers: {
        serializedName: "answers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "QuestionnaireResponseConditionQuestionAnswer"
            }
          }
        }
      }
    }
  }
};

export const QuestionnaireResponseCondition: msRest.CompositeMapper = {
  serializedName: "QuestionnaireResponse_Condition",
  type: {
    name: "Composite",
    className: "QuestionnaireResponseCondition",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Uuid"
        }
      },
      questions: {
        serializedName: "questions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "QuestionnaireResponseConditionQuestion"
            }
          }
        }
      }
    }
  }
};

export const QuestionnaireResponse: msRest.CompositeMapper = {
  serializedName: "QuestionnaireResponse",
  type: {
    name: "Composite",
    className: "QuestionnaireResponse",
    modelProperties: {
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "QuestionnaireResponseCondition"
            }
          }
        }
      }
    }
  }
};

export const InstructionsResponseCondition: msRest.CompositeMapper = {
  serializedName: "InstructionsResponse_Condition",
  type: {
    name: "Composite",
    className: "InstructionsResponseCondition",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      mediaUrl: {
        serializedName: "mediaUrl",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InstructionsResponse: msRest.CompositeMapper = {
  serializedName: "InstructionsResponse",
  type: {
    name: "Composite",
    className: "InstructionsResponse",
    modelProperties: {
      visitTypeName: {
        serializedName: "visitTypeName",
        type: {
          name: "String"
        }
      },
      conditions: {
        serializedName: "conditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InstructionsResponseCondition"
            }
          }
        }
      }
    }
  }
};

export const EvaluateQuestion: msRest.CompositeMapper = {
  serializedName: "EvaluateQuestion",
  type: {
    name: "Composite",
    className: "EvaluateQuestion",
    modelProperties: {
      questionId: {
        required: true,
        serializedName: "questionId",
        type: {
          name: "Uuid"
        }
      },
      answerId: {
        required: true,
        serializedName: "answerId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const EvaluateCondition: msRest.CompositeMapper = {
  serializedName: "EvaluateCondition",
  type: {
    name: "Composite",
    className: "EvaluateCondition",
    modelProperties: {
      conditionId: {
        required: true,
        serializedName: "conditionId",
        type: {
          name: "Uuid"
        }
      },
      visitorId: {
        required: true,
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      },
      questionsWithAnswers: {
        required: true,
        serializedName: "questionsWithAnswers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EvaluateQuestion"
            }
          }
        }
      }
    }
  }
};

export const EvaluateRequestedConditions: msRest.CompositeMapper = {
  serializedName: "EvaluateRequestedConditions",
  type: {
    name: "Composite",
    className: "EvaluateRequestedConditions",
    modelProperties: {
      invitationId: {
        required: true,
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      },
      visitorId: {
        required: true,
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const RegistrationControllerGetAvailableVisitorCardResponse: msRest.CompositeMapper = {
  serializedName: "RegistrationController_GetAvailableVisitorCardResponse",
  type: {
    name: "Composite",
    className: "RegistrationControllerGetAvailableVisitorCardResponse",
    modelProperties: {
      cardNumber: {
        serializedName: "cardNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegistrationControllerRegisterVisitorRequest: msRest.CompositeMapper = {
  serializedName: "RegistrationController_RegisterVisitorRequest",
  type: {
    name: "Composite",
    className: "RegistrationControllerRegisterVisitorRequest",
    modelProperties: {
      visitorId: {
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      },
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      },
      cardNumber: {
        serializedName: "cardNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegistrationControllerUnregisterVisitorRequest: msRest.CompositeMapper = {
  serializedName: "RegistrationController_UnregisterVisitorRequest",
  type: {
    name: "Composite",
    className: "RegistrationControllerUnregisterVisitorRequest",
    modelProperties: {
      visitorId: {
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      },
      invitationId: {
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const VisitorDetailDto: msRest.CompositeMapper = {
  serializedName: "VisitorDetailDto",
  type: {
    name: "Composite",
    className: "VisitorDetailDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstname: {
        serializedName: "firstname",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      birthDate: {
        serializedName: "birthDate",
        type: {
          name: "DateTime"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      companyId: {
        serializedName: "companyId",
        type: {
          name: "Uuid"
        }
      },
      companyStreet: {
        serializedName: "companyStreet",
        type: {
          name: "String"
        }
      },
      companyZip: {
        serializedName: "companyZip",
        type: {
          name: "String"
        }
      },
      companyCity: {
        serializedName: "companyCity",
        type: {
          name: "String"
        }
      },
      companyCountry: {
        serializedName: "companyCountry",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      licensePlate: {
        serializedName: "licensePlate",
        type: {
          name: "String"
        }
      },
      isLicencePlateMandatory: {
        serializedName: "isLicencePlateMandatory",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const VisitorCreate: msRest.CompositeMapper = {
  serializedName: "VisitorCreate",
  type: {
    name: "Composite",
    className: "VisitorCreate",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      companyId: {
        required: true,
        serializedName: "companyId",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      isAdHoc: {
        required: true,
        serializedName: "isAdHoc",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const VisitorCreateResponse: msRest.CompositeMapper = {
  serializedName: "VisitorCreateResponse",
  type: {
    name: "Composite",
    className: "VisitorCreateResponse",
    modelProperties: {
      visitorId: {
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const VisitorUpdate: msRest.CompositeMapper = {
  serializedName: "VisitorUpdate",
  type: {
    name: "Composite",
    className: "VisitorUpdate",
    modelProperties: {
      visitorId: {
        required: true,
        serializedName: "visitorId",
        type: {
          name: "Uuid"
        }
      },
      invitationId: {
        required: true,
        serializedName: "invitationId",
        type: {
          name: "Uuid"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      firstname: {
        required: true,
        serializedName: "firstname",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      birthDate: {
        required: true,
        serializedName: "birthDate",
        type: {
          name: "DateTime"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      licensePlate: {
        serializedName: "licensePlate",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const VisitTypeVisitConditionDTOVisitConditionDTO: msRest.CompositeMapper = {
  serializedName: "VisitTypeVisitConditionDTO_VisitConditionDTO",
  type: {
    name: "Composite",
    className: "VisitTypeVisitConditionDTOVisitConditionDTO",
    modelProperties: {
      conditionId: {
        serializedName: "conditionId",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      validForDays: {
        serializedName: "validForDays",
        type: {
          name: "Number"
        }
      },
      recordOrder: {
        serializedName: "recordOrder",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const VisitTypeVisitConditionDTO: msRest.CompositeMapper = {
  serializedName: "VisitTypeVisitConditionDTO",
  type: {
    name: "Composite",
    className: "VisitTypeVisitConditionDTO",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      visitConditions: {
        serializedName: "visitConditions",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VisitTypeVisitConditionDTOVisitConditionDTO"
            }
          }
        }
      }
    }
  }
};
