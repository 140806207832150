import Vue from 'vue'
import { Mutations, Store, install } from "pipaslot-vuex-typescript";

import invitation from "@/module-admin/stores/invitation"
import app from "@/stores/app"
import notifications from "@/stores/notifier"
import visitor from '@/module-visitor/stores/visitor';

class RootState {
}

class RootMutations extends Mutations<RootState> {  
}

class RootStore extends Store<RootState, RootMutations> {
  app = app;
  invitation = invitation;
  notifications = notifications;
  visitor = visitor;
  
  clear(reloadData: boolean = true){
    this.invitation.reset(reloadData);
    this.visitor.reset();
    this.app.authorize(null);
  }
}

var rootStore = new RootStore(new RootState(), new RootMutations());

Vue.use(install, rootStore);

export default rootStore